import { useSelector } from 'react-redux'
import MainHeader from '../../components/MainTest/MainHeader'
import MainDates from '../../components/MainTest/MainDates'
import { useSocket } from '../../controllers/SocketProvider'
import { getCurrentCityFilterSelect } from '../../help/getCurrentCityFilterSelect'
import { getCurrentCategoryFilterSelect } from '../../help/getCurrentCategoryFilterSelect'

import './styles.scss';


const Main = () => {
  const { addTicketToGrid } = useSocket();


  const { currentMaster } = useSelector((state) => state.application)
  const { tickets, grid, isLoadingGrid, gridCount, isGridUpdate } = useSelector(
    (state) => state.dragContent
  )
  const { searchTickets, cardToDrag, searchValue, currentWorks, currentCity, cityList } = useSelector((state) => state.headerSort)


  const handleCardDrop = (cardId, hourId) => {
    addTicketToGrid({
      ticket_id: cardId,
      masterd_id: currentMaster.master_id,
      timeBegin: hourId,
      gridCount: gridCount,
    })
  }



  const categoryFilterArr = getCurrentCategoryFilterSelect(cardToDrag, currentWorks);
  const cityCurrentFilter = getCurrentCityFilterSelect(cardToDrag, currentCity);
  const cityCurrentTimeZone = cityList.find(item => item.region_name === cityCurrentFilter) ?? null;



  // const getTicketsFilter = () => {
  //   if (!currentCity || (currentCity && !currentCity.region_name) && currentWorks.length === 0) return tickets;
  //   const result = [];
  //
  //   for (let i = 0; i < tickets.length; i++) {
  //     const ticket = tickets[i];
  //
  //     const hasCityFilter =  currentCity.region_name ? currentCity.region_name === ticket.region.region_name : true;
  //     const hasTagsFilter =  currentWorks.length > 0 ? currentWorks.includes(ticket.category.category_name) : true;
  //     if (hasCityFilter && hasTagsFilter) {
  //       result.push(ticket);
  //     }
  //
  //   }
  //
  //   return result;
  // }

  // const ticketsFilters = getTicketsFilter();
// console.log(ticketsFilters)
  return (
    <div className="test-main-wrap">
      {tickets  &&
        <MainHeader tickets={tickets} searchTickets={searchTickets} />
      }
      <MainDates
        selectTimeZone={cityCurrentTimeZone ? cityCurrentTimeZone.timeZone : null}
        searchValue={searchValue}
        selectTaskCategoryFilter={categoryFilterArr}
        selectCityFilter={cityCurrentFilter}
        searchTickets={searchTickets}
        isLoadingGrid={isLoadingGrid}
        grid={grid}
        gridCount={gridCount}
        isGridUpdate={isGridUpdate}
        onCardDrop={handleCardDrop}
      />
    </div>
  )
}

export default Main
