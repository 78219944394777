import React, { useContext, useState } from 'react'
import './styles.scss'
import Button from '../../components/Common/Button'
import Circle from '../../assets/icons/Circle'
import TextArea from '../../components/MainTest/TextArea'
import { usePost } from '../../api/request'
import { useAlert } from '../AlertNotification'
import { useSelector } from 'react-redux'

const Context = React.createContext()

const useComment = () => {
  const { setConfirmItem } = useContext(Context)

  return ({ isVisible = false, ticketId, onClose, isWithMaster }) => {
    setConfirmItem({
      isVisible,
      ticketId,
      onClose,
      isWithMaster,
    })
  }
}

const CommentProvider = ({ children }) => {
  const postU = usePost()
  const alertU = useAlert()

  // const {currentMaster} = useSelector(state => state.application)
  const currentManager = useSelector((state) => state.auth.user)

  const [loading, setLoading] = useState(false)

  const [commentItem, setCommentItem] = useState({
    isVisible: false,
    ticketId: '',
    onClose: () => {},
    isWithMaster: false,
  })

  const onClose = () => {
    setCommentItem({
      isVisible: false,
      ticketId: '',
      onClose: () => {},
      isWithMaster: false,
    })
  }

  const onResponse = () => {
      const start = Date.now();
      setLoading(true)

    const data = {
      ticket_id: commentItem.ticketId,
      description: value,
      master_id: currentManager?.id,
    }
    console.log('click')

    postU('canceled_ticket', data)
        .then((response) => {
            if (response.status === 'success') {

                onClose()
                commentItem?.onClose()

                setValue('')

                alertU({
                    status: 'success',
                    title: 'Отмена заказа',
                    message: 'Вы успешно отменили заказ'
                });
                const dateEnd = Date.now();
                console.log('Лог после отмены заявки, время в сек.')
                console.log( (dateEnd - start) / 1000 )
            }
        })
        .catch((e) => {
            if (e?.response?.data?.message === 'Description is not allowed to be empty'){
                alertU({
                    status: 'error',
                    title: 'Отмена заказа',
                    message: 'Комменнтарий не должен быть пустым'
                });
            } else {
                alertU({
                    status: 'error',
                    title: 'Отмена заказа',
                    message: e?.response?.data?.message
                });
            }
            const dateEnd = Date.now();
            console.log('Лог после отмены заявки, с ошибкой  время в сек.')
            console.log( (dateEnd - start) / 1000 )

        })
        .finally(() => {
            setLoading(false)
          
        })


  }

  const [value, setValue] = useState('')

  return (
    <Context.Provider value={{ setConfirmItem: setCommentItem }}>
      {commentItem.isVisible && (
        <div className="comment-modal">
          <div className="comment-modal-content">
            <TextArea
              rows={10}
              title={'Оставьте комментарий по причине отмены заказа'}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            <div className="comment-modal-content-buttons">
              <Button
                isLoading={loading}
                onClick={onResponse}
                text={'Сохранить'}
              />
              <Button
                onClick={onClose}
                backColor={'#fff'}
                color={'#FD633D'}
                border={'1px solid #FD633D'}
                text={'Отменить'}
              />
            </div>
            <div className="comment-modal-content-close" onClick={onClose}>
              <Circle />
            </div>
          </div>
        </div>
      )}
      {children}
    </Context.Provider>
  )
}

export { CommentProvider, useComment }
