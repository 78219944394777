import React, { useEffect } from 'react'
import { useGet } from '../../api/request'

const HeaderController = ({ children }) => {
  // const getU = useGet()
  //
  // useEffect(() =>{
  //     getU('/region')
  //         .then(() =>{
  //
  //         })
  //         .catch(() =>{
  //
  //         })
  //         .finally(() =>{
  //
  //         })
  // },[])

  return children
}

export default HeaderController
