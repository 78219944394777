import './index.scss'
import { Link, useNavigate } from 'react-router-dom'

import { useLocation } from 'react-router-dom'
import { useState } from 'react'
import { useSelector } from 'react-redux'

const Nav = () => {
  const location = useLocation()

  const [countMessages, setCountMessages] = useState(1)
  const { messageCount } = useSelector((state) => state.messages)

  return (
    <ul className="navbar">
      <Link
        to={'/app/instruction'}
        className="navbar__item"
        style={{
          width: 140,
          maxWidth: 150,
          minWidth: 130,
        }}
      >
        <li className={`navbar__item__link`}>Инструкции</li>
      </Link>
      <Link
        className="navbar__item"
        to={'/app/messages'}
        style={{
          width: 220,
          maxWidth: 220,
          minWidth: 195,
        }}
      >
        <li className="navbar__item__link">Центр сообщений</li>

        {messageCount !== 0 && (
          <div className="navbar__item-count">{messageCount}</div>
        )}
      </Link>
    </ul>
  )
}

export default Nav
