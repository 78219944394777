import { useState } from 'react'
import { useDispatch } from 'react-redux'
import './styles.scss'
import { PasswordHideIcon, PasswordIcon } from '../../../assets/icons/Login'

const TextArea = ({
  bcgColor = '#fff',
  isWithBoxShadow = false,
  value,
  onChange,
  leftIcon,
  error,
  rightIcon,
  title,
  password,
  disabled,
  rows = 2,
  ...props
}) => {
  const [isFocus, setIsFocus] = useState(false)

  return (
    <div className="text-area">
      {!!title && <div className="text-area-title">{title}</div>}

      <textarea
        style={{
          height: 97,
        }}
        rows={rows}
        disabled={disabled}
        className="text-area-main"
        value={value}
        onChange={onChange}
        autoComplete="off"
        onFocus={() => {
          setIsFocus(true)
        }}
        onBlur={() => {
          setIsFocus(false)
        }}
        {...props}
      />
    </div>
  )
}

export default TextArea
