import React, { useEffect, useRef, useState } from 'react'
import './style.scss'
import { Oval } from 'react-loader-spinner'
import { setCurrentCity, setCurrentWorks } from '../../../redux/headerSort'
import { useDispatch } from 'react-redux'

const DropDown = ({
  startIcon,
  backIcon,
  currentItem,
  setCurrentItem,
  itemsForChoose,
  isLoadingCity,
}) => {
  const dispatch = useDispatch()

  const dropDownListRef = useRef(null)
  const [isShow, setIsShow] = useState(false)

  const onChange = (item) => {
    setIsShow(false)
    setCurrentItem(item)
  }

  const onClearDropDown = () => {
    dispatch(setCurrentCity(null))
    setIsShow(false)
  }

  useEffect(() => {
    if (isShow) {
      const handleClickOutside = (event) => {
        if (
          dropDownListRef.current &&
          !dropDownListRef.current.contains(event.target)
        ) {
          setIsShow(false)
        }
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }
  }, [isShow, dropDownListRef])

  return (
    <div className="drop-down-button__wrap">
      <button
        className="drop-down-button__wrap-main"
        onClick={() => setIsShow(!isShow)}
      >
        {!!startIcon && startIcon}
        <h5>{currentItem ? currentItem : 'Выберите город'}</h5>
        {!!backIcon && backIcon}
      </button>
      {isShow && (
        <div className="drop-down-button__wrap-drop-wrap" ref={dropDownListRef}>
          {isLoadingCity ? (
            <Oval
              strokeWidth={5}
              strokeWidthSecondary={5}
              secondaryColor={'rgba(253,99,61, 0.6)'}
              height="20"
              width="20"
              color="#fff"
            />
          ) : (
            <>
              <div className="drop-down-button__wrap-drop-wrap-list">
                {itemsForChoose.filter(item => item.region_name).map((item,index) => (
                  <li
                    key={index}
                    onClick={() => onChange(item)}
                    className={currentItem && currentItem === item.region_name && 'active'}
                  >
                    {item.region_name}
                  </li>
                ))}
              </div>
              <div className="drop-down-check-box-button__wrap-drop-wrap-clear">
                <button
                  disabled={!currentItem}
                  onClick={onClearDropDown}
                  className="drop-down-check-box-button__wrap-drop-wrap-clear-add"
                >
                  Очистить
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default DropDown
