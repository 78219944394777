import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setIsDesktop, setIsMob } from '../../redux/adaptive'

const AdapriveController = ({ children }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    const handleResize = () => {
      dispatch(setIsMob(window.innerWidth < 1040))
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  useEffect(() => {
    const handleResize = () => {
      dispatch(setIsDesktop(window.innerWidth < 1400))
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return children
}

export default AdapriveController
