import { createStore, combineReducers, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import auth from './redux/auth'
import adaptive from './redux/adaptive'
import dragContent from './redux/dragContent'
import headerSort from './redux/headerSort'
import application from './redux/application'
import messages from './redux/messages'
import instructions from './redux/instructions/instructions'
const appReducer = combineReducers({
  auth,
  adaptive,
  dragContent,
  headerSort,
  application,
  messages,
  instructions,
})

const persistConfig = {
  key: 'root-Swaper',
  storage,
  whitelist: ['auth'],
}

const persistedReducer = persistReducer(persistConfig, appReducer)

const store = createStore(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default store
export const persistor = persistStore(store)
