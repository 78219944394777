import moment from "moment";


function pad(num) {
    return ("0" + num).slice(-2);
}

function formatDate(d) {
    return [d.getUTCFullYear(),
          pad(d.getUTCMonth() + 1),
          pad(d.getUTCDate())].join("-") + "T" +
      [pad(d.getUTCHours()),
          pad(d.getUTCMinutes()),
          pad(d.getUTCSeconds())].join(":") + "Z";
}



export const CheckJustHours = (hour, selectTimeZone) => {
    const dateNow = new Date();
    const currenHour = new Date(hour);

    if (selectTimeZone) {
        // dateNow.setDate(dateNow.getDate() + selectTimeZone);
        // currenHour.setDate(currenHour.getDate() + selectTimeZone);
        // console.log('selectTimeZone:', currenHour.getDate() + selectTimeZone );
    }

    const dateNowGreenwich = formatDate(dateNow);
    const currentHourGreenwich = formatDate(currenHour);

    // console.log('dateNowGreenwich:', dateNowGreenwich );
    // console.log('currentHourGreenwich', currentHourGreenwich)


    if (hour) {
        if (dateNowGreenwich < currentHourGreenwich) {
            return true;
        } else {
            return false;
        }
    }
}

export const getTimeZoneMomentTime = (time, selectTimeZone) => {

    if (selectTimeZone) {
    const currentTime = new Date(time);
    currentTime.setHours(currentTime.getHours() + selectTimeZone);
    const dateTimeHours = new Date(currentTime).getUTCHours();
    return `${dateTimeHours < 10 ? '0' : ''}${dateTimeHours}.00`
    } else {
        return moment(time).format('HH.mm');
    }
}