import React, { useCallback, useEffect, useState } from 'react'
import { setGridCount, setIsLoadingGrid } from '../../../redux/dragContent'
import { useDispatch } from 'react-redux'
import { useSocket } from '../../../controllers/SocketProvider'
import moment from 'moment'
import { Oval } from 'react-loader-spinner'
import 'moment/locale/ru'
import Button from '../../Common/Button'
import { getCurrentTime } from '../../../help/getCurrentTime'
import MainDatesHour from '../MainDatesHour/index'
import MasterCard from '../master-card/master-card'

import './styles.scss'

const MainDates = ({ grid, isLoadingGrid, onCardDrop, selectTaskCategoryFilter, selectCityFilter, searchValue, selectTimeZone }) => {
  const [gridCountMasters, setGridCountMasters] = useState(1)
  const [buttonVisible, setButtonVisible] = useState(true)
  const [currentTime, setCurrentTime] = useState(() => getCurrentTime())
  moment.locale('ru')

  const dispatch = useDispatch()
  const { getGridByMaster } = useSocket()




  const handleRequestMore = () => {
    setGridCountMasters((prevCount) => prevCount + 1)
    setTimeout(() => {
      if (gridCountMasters === 3) {
        setButtonVisible(false)
      }
    }, 2000)
  }

  const getGrid = () => dispatch(setIsLoadingGrid(true))

  useEffect(() => {
    const interval = setInterval(() => {
      const cur = getCurrentTime()
      if (cur !== currentTime) {
        // console.log(cur, currentTime)
        setCurrentTime(cur)
      }
    }, 10000)
    return () => {
      console.log('clear')
      clearInterval(interval)
    }
  }, [currentTime])

  useEffect(() => {
    getGridByMaster(gridCountMasters)
    dispatch(setGridCount(2))
    getGrid()
  }, [gridCountMasters])

  // if (grid && grid.data) {
    // console.log(grid.data[0].masters[3].schedule.hours[14])
  //   console.log(grid.data[0].masters)
  // }

const checkMasterTags = (filterTagsList, masterTagsList) => {
    if (!filterTagsList || (filterTagsList && filterTagsList.length === 0)) {
      return true;
    }
    let hasTags = false;

  for (let i = 0; i < filterTagsList.length; i++) {
    const currentTag = filterTagsList[i];
    if (masterTagsList.includes(currentTag)) {
      hasTags = true;
    }
  }

    return hasTags;
}

  const filterSelectSityAndTags = () => {
    if (!grid.data || grid.data.length === 0 ) return [];
    const result = [];


    if (!selectTaskCategoryFilter && !selectCityFilter) {
      return grid.data;
    }


    for (let i = 0; i <= grid.data.length; i++) {
      const day = grid.data[i];
      result.push({ ...day, masters: [] });

      if (day && day.date && day.masters.length > 0) {
        for (let j = 0; j < day.masters.length; j++) {
          const master = day.masters[j];
          const masterTags = master.tags;
          const masterCity = master.cities.filter(item => item.region_name).map(item => item.region_name);
          const hasCytiFilter =  selectCityFilter ? masterCity.includes(selectCityFilter) : true;
          const hasTagsFilter =  selectTaskCategoryFilter && selectTaskCategoryFilter.length > 0 ? checkMasterTags(selectTaskCategoryFilter, masterTags) : true;
          const isSearchName = searchValue.length > 0 ? master.fio.toLowerCase().includes(searchValue.toLowerCase()) : true;


          if (hasCytiFilter && hasTagsFilter && isSearchName) {
            result[i].masters.push(master);
          }

        }
      }
    }

    return result;
  }

  const filtersMasters = filterSelectSityAndTags();

  const getUserTimeIso = useCallback((user) => {
    if (!selectCityFilter) return '';
    let result = '';

  const findCurrentFilterCity = user.cities.find(item => item.region_name === selectCityFilter);

  if (findCurrentFilterCity && findCurrentFilterCity.region_name && findCurrentFilterCity.timeZone !== null) {
    result = `${findCurrentFilterCity.region_name} UTC ${findCurrentFilterCity.timeZone > 0 ? '+' : ''}${findCurrentFilterCity.timeZone}`;
  }
    return result;
  },[selectCityFilter])


  return (
    <div className="dates-block">
      <div className="date-block">
        <h1 className="title-all">Все исполнители</h1>

        {filtersMasters &&
          filtersMasters.length > 0 &&
          filtersMasters
            // .slice(0,1)
            .map((date, index) => (
            <div key={index} className="dates-block-header">
              <h2 className="dates-block-dates-wrap-item-date">
                {moment(date.date)
                  //   .subtract(1, 'days')
                  .format('dddd DD.MM.yyyy')}
              </h2>

              <div className="masters-line">
                {date.masters.length > 0 ? (
                  date.masters
                    // .filter(item => item.fio === 'Руслан')
                    .map((item, index) => (
                    <div key={index} className="master-card-root">
                      <MasterCard
                        timeIso={getUserTimeIso(item)}
                        role={item.role}
                        IgnoreTimeRange={item.IgnoreTimeRange}
                        fio={item.fio}
                        lift={item.lift}
                      />

                      {/* --------------------------список часов в карточке------------------------------------------- */}
                      <div className="dates-block-dates-wrap-item-date-scroll">
                        {item.schedule?.hours?.map((hour) => (
                          <MainDatesHour
                            selectTimeZone={selectTimeZone}
                            key={hour.id}
                            hour={hour}
                            ticket={hour.ticket}
                            currentTime={currentTime}
                            master={item}
                          />
                        ))}
                      </div>
                      {/* --------------------------список часов в карточке------------------------------------------- */}
                    </div>
                  ))
                ) : (
                  <h2 className="text-not-found-master">
                    Мастеров за выбранную дату не найдено
                  </h2>
                )}
              </div>
            </div>
          ))}

        {/* --------------------------список карточек ------------------------------------------- */}

        {!grid.data && (
          <div className="dates-block-dates-wrap-loading">
            <Oval
              strokeWidth={5}
              strokeWidthSecondary={5}
              secondaryColor={'rgba(253,99,61, 0.6)'}
              height="50"
              width="50"
              color="#fff"
            />
          </div>
        )}
      </div>

      {grid.data && grid.data.length > 0 && (
        <div className="button-more-master">
          {buttonVisible && (
            <Button
              isLoading={isLoadingGrid}
              onClick={handleRequestMore}
              text="Загрузить еще"
            />
          )}
        </div>
      )}
    </div>
  )
}

export default MainDates
