import React from 'react';

export const ArrowToBottom = ({color = "#fff"}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 7.83329L6.16667 6.66663L10 10.5L13.8333 6.66663L15 7.83329L10 12.8333L5 7.83329Z" fill={color}/>
        </svg>
    );
};

export const ArrowToTop = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 12.1666L13.8333 13.3333L10 9.49998L6.16667 13.3333L5 12.1666L10 7.16665L15 12.1666Z" fill="#202328"/>
        </svg>
    );
};


export const ArrowToLeft = ({color = '#202328'}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.1663 5L13.333 6.16667L9.49967 10L13.333 13.8333L12.1663 15L7.16634 10L12.1663 5Z"
                  fill={color}/>
        </svg>

    );
};


export const SwapperArrowToRight = ({color = '#fff'}) => {
    return (
        <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.75 15L0 13.25L5.75 7.5L0 1.75L1.75 0L9.25 7.5L1.75 15Z" fill="white"/>
        </svg>
    );
};

export const SwapperArrowToLeft = ({color = '#202328'}) => {
    return (
        <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.25 15L10 13.25L4.25 7.5L10 1.75L8.25 0L0.750001 7.5L8.25 15Z" fill="white"/>
        </svg>
    );
};



