import React, { useState, useEffect } from 'react'
import './index.scss'
import { Outlet, useNavigate } from 'react-router-dom'
import Header from '../../components/HeaderComponents/Header'
import Footer from '../../components/Footer'
import { useSelector } from 'react-redux'
import HeaderController from '../../controllers/HeaderController'
import SocketProvider from '../../controllers/SocketProvider'
import { OrderProvider } from '../../controllers/ModalOrder'
import { CommentProvider } from '../../controllers/ModalComment'
import { WarningProvider } from '../../controllers/ModalWarning'

const MainLayout = () => {
  const navigate = useNavigate()

  const { accessToken } = useSelector((state) => state.auth)
  const { currentMaster } = useSelector((state) => state.application)

  // console.log('currentMaster')
  // console.log(currentMaster)

  useEffect(() => {
    if (!accessToken) {
      navigate('/login', { replace: true })
    }
  }, [accessToken])

  return (
    <OrderProvider>
      <HeaderController>
        <SocketProvider>
          <div className="container">
            <div className="main-header">
              <Header />
            </div>
            <div className="main-wrapper">
              <Outlet />
            </div>
            {/*<div className="main-footer">*/}
            {/*    /!*<Footer/>*!/*/}
            {/*</div>*/}
          </div>
        </SocketProvider>
      </HeaderController>
    </OrderProvider>
  )
}

export default MainLayout
