import React, { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { io } from 'socket.io-client'
import { IO } from '../../index'
import {
  addNewOneMessage,
  initMessages,
  setCurrentPage,
  setIsLoading,
  setIsLoadingPage,
  setMessageCount,
  setMessages,
  setTotalCount,
} from '../../redux/messages'
import {
  setGrid,
  setIsGridUpdate,
  setIsLoadingGrid,
  setNewTicket,
  setTickets,
} from '../../redux/dragContent'
import { useAlert } from '../AlertNotification'
import { logout, setTokens, setUser } from '../../redux/auth'
import TokenStorage from '../../api/TokenStorage'
import { useNavigate } from 'react-router-dom'
import {
  configureTransport,
  controller,
  getTransport,
} from '../../api/transport'
import { get, useGet } from '../../api/request'

const SocketContext = React.createContext()

export const useSocket = () => {
  const { getGridByMaster, addTicketToGrid, getMessagesByPage } =
    useContext(SocketContext)

  return {
    getGridByMaster,
    addTicketToGrid,
    getMessagesByPage,
  }
}

export const SocketProvider = ({ children }) => {
  const dispatch = useDispatch()
  const alertU = useAlert()
  const navigate = useNavigate()

  const socket = useRef(null)
  const getU = useGet()
  const isLoading = useRef(0) // Число для сохранения состояний запросов. Ести текущих запросов в выполнении нет, он пустой

  const { accessToken, refreshToken, user } = useSelector((state) => state.auth)
  const { messageCount } = useSelector((state) => state.messages)

  const tryToRefresh = async () => {
    try {
      const res = await get('refresh', {
        headers: { Authorization: `Bearer ${refreshToken}` },
      })
      await configureTransport(res.data.tokens.accessToken)
      await dispatch(
        setTokens({
          accessToken: res.data.tokens.accessToken,
          refreshToken: res.data.tokens.refreshToken,
        })
      )
      await dispatch(setUser(res.tokens.user))

      await TokenStorage.setAccessToken(res.data.tokens.accessToken)
      await TokenStorage.setRefreshToken(res.data.tokens.refreshToken)
      await TokenStorage.setUser(res.tokens.user)
    } catch (err) {
      dispatch(logout())
      TokenStorage.logOut()
      navigate('/login')
      // throw e;
    }
  }

  useEffect(() => {
    socket.current = io.connect(process.env.REACT_APP_API_URL, {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 5,
      auth: {
        token: accessToken,
      },
    })

    //получение всех заявок
    socket.current.on('ticket-get', (data) => {
      // console.log('ticket-get')
      // console.log(data)
      dispatch(setTickets(data || []))
    })

    //получение нового тикета
    socket.current.on('ticket-add', (data) => {
      // console.log('data')
      // console.log(data)
      if (data) {
        dispatch(setNewTicket(data))
      }
    })

    //полная сетка
    socket.current.on('ticket-to-master-get', async (data) => {
      console.log('ticket-to-master-get', 'Получили данные')
      // console.log(data)
      await dispatch(setIsGridUpdate(data?.status))
      await dispatch(setGrid(data))
      if (isLoading.current > 0) isLoading.current -= 1
      // console.log(isLoading.current)
      if (!isLoading.current) {
        await dispatch(setIsLoadingGrid(false))
      }
    })

    //ошибки тикетов
    socket.current.on('exeption:tickets', (data) => {
      console.log('exeption:tickets')
      // console.log(data)
      if (data?.listener === 'ticket-to-master-add') {
        alertU({
          status: 'error',
          title: 'Добавление завки в график',
          message: data?.message,
        })
      }
      if (data?.listener === 'exeption:auth') {
        // alertU({
        //     status: 'error',
        //     title: 'Добавление завки в график',
        //     message: data?.message
        // });
        // tryToRefresh()
        dispatch(logout())
        TokenStorage.logOut()
        navigate('/login')
      }
    })

    //ошитки токенов
    socket.current.on('exeption:auth', (data) => {
      console.log('exeption:auth')
      // console.log(data)

      dispatch(logout())
      TokenStorage.logOut()
      navigate('/login')
    })

    //все сообщения
    socket.current.on('message-get', (data) => {
      console.log('message-get')
      // console.log(data)

      if (data?.page === 1) {
        dispatch(initMessages(data?.message))
      } else {
        dispatch(setMessages(data?.message))
      }

      dispatch(setIsLoading(false))
      dispatch(setIsLoadingPage(false))
      dispatch(setCurrentPage(data?.page))
      dispatch(setTotalCount(data?.totalCount))
    })

    //последнее сообщение
    socket.current.on('message-add', (data) => {
      // console.log('message-add')
      // console.log(data)
      dispatch(addNewOneMessage(data))
      dispatch(setMessageCount(messageCount + 1))
    })
  }, [accessToken])

  const getGridByMaster = (gridCount) => {
    isLoading.current += 1
    // console.log(gridCount, 'day')
    getU('worker/verifyAccess')
      .then((res) => {
        if (res?.status === 'success') {
          console.log('ticket-to-master-get', 'Запрос на получение данных')
          setTimeout(
            async () =>
              socket.current.emit('ticket-to-master-get', {
                gridCount: gridCount,
                token: accessToken || TokenStorage.getAccessToken(),
              }),
            1000
          )
        }
      })
      .catch((e) => console.log(e))

    // socket.current.emit("ticket-to-master-get", masterId);
  }

  const getMessagesByPage = (page) => {
    console.log({
      page,
      token: accessToken || TokenStorage.getAccessToken(),
    })
    getU('worker/verifyAccess')
      .then((res) => {
        if (res?.status === 'success') {
          socket?.current?.emit('message-get', {
            page,
            token: accessToken || TokenStorage.getAccessToken(),
          })
        }
      })
      .catch((e) => console.log(e))
  }

  const addTicketToGrid = ({ ticket_id, master_id, timeBegin, gridCount }) => {
    isLoading.current += 1
    console.log({
      token: accessToken || TokenStorage.getAccessToken(),
      ticket_id: ticket_id,
      master_id: master_id,
      timeBegin: timeBegin,
      gridCount: gridCount,
    })

    getU('worker/verifyAccess')
      .then((res) => {
        if (res?.status === 'success') {
          socket.current.emit('ticket-to-master-add', {
            token: accessToken || TokenStorage.getAccessToken(),
            ticket_id: ticket_id,
            master_id: master_id,
            timeBegin: timeBegin,
            gridCount: gridCount,
          })
        }
      })
      .catch((e) => console.log(e))
  }

  return (
    <SocketContext.Provider
      value={{
        getGridByMaster,
        addTicketToGrid,
        getMessagesByPage,
      }}
    >
      {children}
    </SocketContext.Provider>
  )
}

export default SocketProvider

