import './styles.scss'
import Loader from '../Loader'
import { Oval } from 'react-loader-spinner'

const Button = ({
  disabled,
  startIcon,
  text,
  backColor = '#FD633D',
  isLoading,
  onClick,
  height = 56,
  fz = 16,
  color = '#fff',
  border,
}) => {
  // console.log(isLoading, 'isloading')
  return (
    <>
      <button
        className="button"
        onClick={onClick}
        disabled={isLoading || disabled}
        style={{
          height: height,
          backgroundColor: backColor,
          border: border,
        }}
      >
        <div className="startIcon">{startIcon && startIcon}</div>
        <p
          style={{
            fontSize: fz,
            color: color,
          }}
        >
          {text}
        </p>
        {isLoading && (
          <div className="button-loader">
            <Oval
              strokeWidth={5}
              strokeWidthSecondary={5}
              secondaryColor={'rgba(253,99,61, 0.6)'}
              height="20"
              width="20"
              color="#fff"
            />
          </div>
        )}
      </button>
    </>
  )
}

export default Button
