import React from 'react';

const DrugIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.6" clipPath="url(#clip0_545_1200)">
                <path
                    d="M23.19 10.5001L18.39 7.21506L17.325 8.71506L20.76 11.0701H12.93V3.24006L15.285 6.67506L16.785 5.61006L13.5 0.810059C13.3253 0.577191 13.0989 0.388185 12.8385 0.258008C12.5782 0.127831 12.2911 0.0600586 12 0.0600586C11.7089 0.0600586 11.4218 0.127831 11.1615 0.258008C10.9011 0.388185 10.6746 0.577191 10.5 0.810059L7.17 5.61006L8.67 6.67506L11.055 3.24006V11.0701H3.24L6.675 8.71506L5.61 7.21506L0.809998 10.5001C0.57713 10.6747 0.388124 10.9012 0.257947 11.1615C0.12777 11.4219 0.0599976 11.709 0.0599976 12.0001C0.0599976 12.2911 0.12777 12.5782 0.257947 12.8386C0.388124 13.0989 0.57713 13.3254 0.809998 13.5001L5.61 16.7851L6.675 15.2851L3.24 12.9301H11.055V20.7601L8.715 17.3251L7.215 18.3901L10.5 23.1901C10.6723 23.4407 10.903 23.6456 11.1722 23.7872C11.4413 23.9287 11.7409 24.0027 12.045 24.0027C12.3491 24.0027 12.6487 23.9287 12.9178 23.7872C13.187 23.6456 13.4177 23.4407 13.59 23.1901L16.875 18.3901L15.375 17.3251L12.93 20.7601V12.9301H20.76L17.325 15.2851L18.39 16.7851L23.19 13.5001C23.4229 13.3254 23.6119 13.0989 23.7421 12.8386C23.8722 12.5782 23.94 12.2911 23.94 12.0001C23.94 11.709 23.8722 11.4219 23.7421 11.1615C23.6119 10.9012 23.4229 10.6747 23.19 10.5001Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_545_1200">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default DrugIcon;