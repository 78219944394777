import './styles.css'

const CheckBox = ({ value, onChange, ...props }) => {
  return (
    <div className={`checkbox`}>
      <div className={`checkbox__wrap${value ? ' checkbox__wrap_check' : ''}`}>
        {value && (
          <svg
            width="10"
            height="7"
            viewBox="0 0 10 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.7748 6.49999L0.924805 3.64999L1.6373 2.93749L3.7748 5.07499L8.3623 0.487488L9.0748 1.19999L3.7748 6.49999Z"
              fill="white"
            />
          </svg>
        )}
      </div>
    </div>
  )
}

export default CheckBox
