import { Navigate } from 'react-router-dom'
import MainLayout from '../layouts/MainLayout'
import NotFound from '../pages/NotFound'
import Login from '../pages/Login'
import LoginLayout from '../layouts/LoginLayout'
import Instructions from '../pages/Instructions'
import Messages from '../pages/Messages'
import Main from '../pages/Main'
import InstructionDetails from '../pages/instructionDetails'

const navigation = [
  {
    path: '/app',
    element: <MainLayout />,
    children: [
      { path: 'main', element: <Main /> },
      { path: 'instruction', element: <Instructions /> },
      { path: 'messages', element: <Messages /> },
      { path: 'instruction/:id', element: <InstructionDetails /> },
    ],
  },
  {
    path: '',
    element: <LoginLayout />,
    children: [
      { path: 'login', element: <Login /> },

      { path: '/', element: <Navigate to="/app/main" /> },
      { path: '404', element: <NotFound /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
]

export default navigation
