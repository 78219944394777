import React, { useEffect, useRef, useState } from 'react'
import './style.scss'

const DropDownHours = ({
  startIcon,
  backIcon,
  currentItem,
  setCurrentItem,
  itemsForChoose,
}) => {
  const dropDownListRef = useRef(null)
  const [isShow, setIsShow] = useState(false)

  const onChange = (item) => {
    setIsShow(false)
    setCurrentItem(item)
  }

  useEffect(() => {
    if (isShow) {
      const handleClickOutside = (event) => {
        if (
          dropDownListRef.current &&
          !dropDownListRef.current.contains(event.target)
        ) {
          setIsShow(false)
        }
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }
  }, [isShow, dropDownListRef])

  return (
    <div className="drop-down-order-button__wrap">
      <div className="drop-down-order-button__wrap-title">Время (час)</div>
      <button
        className="drop-down-order-button__wrap-main"
        onClick={() => setIsShow(!isShow)}
      >
        <h5>{currentItem ? currentItem : '2'}</h5>
        {!!backIcon && backIcon}
      </button>
      {isShow && (
        <div
          className="drop-down-order-button__wrap-drop-wrap"
          ref={dropDownListRef}
        >
          <div className="drop-down-order-button__wrap-drop-wrap-list">
            {itemsForChoose.map((item) => (
              <li
                onClick={() => onChange(item)}
                className={currentItem === item && 'active'}
              >
                {item}
              </li>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default DropDownHours
