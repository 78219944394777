import React, { useEffect, useState } from 'react'
import { ArrowToBottom, ArrowToTop } from '../../../assets/icons/Arrows'
import './styles.scss'
import { useNavigate } from 'react-router-dom'
const InstructionItem = ({ item, level }) => {
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()
  const toggleOpen = (e) => {
    e.stopPropagation()
    setIsOpen((prev) => !prev)
  }
  useEffect(() => {
    if (item.id === 1) setIsOpen(true)
  }, [])
  return (
    <div
      className="instruction-item"
      style={{ paddingLeft: `${level * 20}px` }}
    >
      <div onClick={toggleOpen} className="instruction-item-title">
        <h3 className="instruction-item-title-text">
          {item.name === 'Менеджера' ? 'Инструкции менеджеров' : item.name}
        </h3>
        {isOpen ? (
          <ArrowToTop color={'#202328'} />
        ) : (
          <ArrowToBottom color={'#202328'} />
        )}
      </div>
      {isOpen && (
        <>
          {item.instructions && item.instructions.length > 0 && (
            <div className="instruction-item-instructions">
              {item.instructions.map((instruction) => (
                <p
                  onClick={() => navigate(`/app/instruction/${instruction.id}`)}
                  key={instruction.id}
                  className="instruction-item-instruction"
                >
                  {instruction.title}
                </p>
              ))}
            </div>
          )}
          {item.childSections && (
            <div className="instruction-item-children">
              {item.childSections.map((childItem) => (
                <InstructionItem
                  item={childItem}
                  key={childItem.id}
                  level={level + 1}
                />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default InstructionItem
