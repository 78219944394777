import React, { useEffect, useRef, useState } from 'react'
import './style.scss'
import CheckBox from '../../Common/CheckBox'
import { Oval } from 'react-loader-spinner'
import {
  setCurrentTypeOfWork,
  setCurrentWorks,
} from '../../../redux/headerSort'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

const DropDownCheckBox = ({
  startIcon,
  backIcon,
  currentItem,
  setCurrentItem,
  onCleaer,
  itemsForChoose,
  isLoading,
}) => {
  const dispatch = useDispatch()

  const { currentWorks } = useSelector((state) => state.headerSort)

  const dropDownListRef = useRef(null)
  const [isShow, setIsShow] = useState(false)

  const onConfirmTypeOfWork = () => {
    dispatch(
      setCurrentWorks([
        ...currentWorks,
        ...currentItem.filter((el) => !currentWorks.includes(el)),
      ])
    )
    dispatch(setCurrentTypeOfWork([]))
    setIsShow(false)
  }

  const onClearCheckBoxDropDown = () => {
    dispatch(setCurrentWorks([]))
    dispatch(setCurrentTypeOfWork([]))
    // setIsShow(false)
  }

  const onChange = (item) => {
    // setIsShow(false)
    setCurrentItem(item)
  }

  const showText = () => {
    let map = currentWorks.map((item) => item?.category_name)

    if (map.join(', ').length > 11) {
      return map.join(', ').slice(0, 11) + '...'
    }

    return map.join(', ')
  }

  useEffect(() => {
    if (isShow) {
      const handleClickOutside = (event) => {
        if (
          dropDownListRef?.current &&
          !dropDownListRef?.current?.contains(event?.target)
        ) {
          setIsShow(false)
        }
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }
  }, [isShow, dropDownListRef])

  useEffect(() => {
    // console.log(currentItem, currentWorks)
  }, [currentItem, currentWorks])

  return (
    <div className="drop-down-check-box-button__wrap">
      <button
        className="drop-down-check-box-button__wrap-main"
        onClick={() => setIsShow(!isShow)}
      >
        {!!startIcon && startIcon}
        <h5>{currentWorks?.length > 0 ? showText() : 'Список работ'}</h5>
        {!!backIcon && backIcon}
      </button>
      {isShow && (
        <div
          className="drop-down-check-box-button__wrap-drop-wrap"
          ref={dropDownListRef}
        >
          {isLoading ? (
            <Oval
              strokeWidth={5}
              strokeWidthSecondary={5}
              secondaryColor={'rgba(253,99,61, 0.6)'}
              height="20"
              width="20"
              color="#fff"
            />
          ) : (
            <>
              <div className="drop-down-check-box-button__wrap-drop-wrap-list">
                {itemsForChoose.map((item) => (
                  <li
                    onClick={() => onChange(item)}
                    className={currentItem?.id === item?.id && 'active'}
                  >
                    <CheckBox
                      value={currentItem.some(
                        (currentCheck) => currentCheck?.id === item?.id
                      )}
                    />

                    {item?.category_name}
                  </li>
                ))}
              </div>
              <div className="drop-down-check-box-button__wrap-drop-wrap-clear">
                <button
                  disabled={currentWorks.length <= 0}
                  onClick={onClearCheckBoxDropDown}
                  className="drop-down-check-box-button__wrap-drop-wrap-clear-add"
                >
                  Очистить
                </button>
                <button
                  disabled={currentItem.length <= 0}
                  onClick={onConfirmTypeOfWork}
                  className="drop-down-check-box-button__wrap-drop-wrap-clear-clear"
                >
                  Добавить
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default DropDownCheckBox
