import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import BackButton from '../../components/Common/BackButton'
import styles from './instructionDetails.module.css'
import { Oval } from 'react-loader-spinner'
import { useEffect, useState } from 'react'
import { useGet } from '../../api/request'
const InstructionDetails = () => {
  const { id } = useParams()
  console.log(id)
  const navigate = useNavigate()
  const getU = useGet()
  const [data, setData] = useState({})
  //   const data = useSelector((state) => state.instructions.section)
  //   const item = data.find((item) => item.id === Number(id))
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    getU(`/instruction/${id}`)
      .then((res) => setData(res.data.Instruction))
      .catch((e) => console.error(e))
      .finally(setIsLoading(false))
  }, [])
  const filteredData = data.data
    ? data.data.replace(/<[^>]+>&nbsp;<\/[^>]+>/g, '')
    : ''
  const createMarkup = (htmlString) => {
    return { __html: htmlString }
  }
  console.log(data.data)
  return (
    <div style={{ paddingTop: '20px', width: '100%' }}>
      <BackButton title={'Назад'} onPress={() => navigate(-1)} />
      <div className={styles.root}>
        {isLoading ? (
          <Oval
            strokeWidth={5}
            strokeWidthSecondary={5}
            secondaryColor={'rgba(253,99,61, 0.6)'}
            height="50"
            width="50"
            color="#fff"
          />
        ) : data ? (
          <div className={styles.content}>
            <h1>{data.title}</h1>
            <div dangerouslySetInnerHTML={createMarkup(filteredData)} />
          </div>
        ) : (
          <p>Данные не найдены</p>
        )}
      </div>
    </div>
  )
}

export default InstructionDetails
