import React, { useEffect, useState } from 'react'
import { useDrag } from 'react-dnd'
import './styles.scss'
import { useOrder } from '../../../controllers/ModalOrder'
import DrugIcon from '../../../assets/icons/DrugIcon'
import { useDispatch, useSelector } from 'react-redux'
import {
  setCardToDrag,
  setCurrentCity,
  setCurrentTypeOfWork,
  setCurrentWorks,
} from '../../../redux/headerSort'
import ShowMewIcon from '../../../assets/icons/ShowMewIcon'

const CardHeader = ({ card, id }) => {
  const orderU = useOrder()
  const dispatch = useDispatch()

  const { cardToDrag } = useSelector((state) => state.headerSort)

  const [{ isDragging }, drag] = useDrag({
    type: 'ticket',
    item: () => ({ id }),
    canDrag: () => {
      return cardToDrag?.id === id ? true : false
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0.5 : 1

  const onOpenCard = (id) => {
    // console.log(id)
    orderU({
      isVisible: true,
      id: id,
      isWithMaster: false,
    })
  }

  const borderColor = card.status === 'Отказано' ? '#EB5757' : '#6FCF97'
  const borderColorPick = card.status === 'Отказано' ? '#861010' : '#219653'

  useEffect(() => {
    if (cardToDrag) {
      dispatch(setCurrentCity(cardToDrag?.region))
      dispatch(setCurrentWorks([cardToDrag?.category]))
      dispatch(setCurrentTypeOfWork([cardToDrag?.category]))
    } else {
      dispatch(setCurrentCity(null))
      dispatch(setCurrentWorks([]))
      dispatch(setCurrentTypeOfWork([]))
    }
  }, [cardToDrag])

  return (
    <div style={{ position: 'relative' }}>
      <div
        ref={drag}
        // onClick={() => onOpenCard(id)}
        onClick={() =>
          dispatch(setCardToDrag(card.id === cardToDrag?.id ? null : card))
        }
        className="header-card-item"
        style={{
          opacity,
          backgroundColor: borderColor,
          border:
            cardToDrag?.id === id
              ? `3px solid ${borderColorPick}`
              : `1px solid ${borderColorPick}`,
        }}
      >
        <div
          style={{
            marginBottom: 2,
          }}
          className="header-card-item-big-text"
        >
          {card?.phone}
        </div>
        <div className="header-card-item-small-text">{card.description}</div>
        <span className="header-card-item-big-text">
          {card?.address?.length > 23
            ? card.address.slice(0, 20) + '...'
            : card.address}
        </span>
        <div className="header-card-item-big-text">{card.price} р.</div>
      </div>
      <div className="header-card-item-drug-icon">
        {cardToDrag?.id === id && (
          <div
            style={{
              marginBottom: 5,
            }}
          >
            <DrugIcon />
          </div>
        )}
        <div onClick={() => onOpenCard(id)} style={{ cursor: 'pointer' }}>
          <ShowMewIcon />
        </div>
      </div>
    </div>
  )
}

export default CardHeader
