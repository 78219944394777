import React, { useContext, useEffect, useState } from 'react'
import './styles.scss'
import Button from '../../components/Common/Button'
import Circle from '../../assets/icons/Circle'

const Context = React.createContext()

const useWarning = () => {
  const { setConfirmItem } = useContext(Context)

  return ({ isVisible = false, text1 = '', text2 = '' }) => {
    setConfirmItem({
      isVisible,
      text1,
      text2,
    })
  }
}

const WarningProvider = ({ children }) => {
  const [warningItem, setWarningItem] = useState({
    isVisible: false,
    text1: '',
    text2: '',
  })

  const onClose = () => {
    setWarningItem({
      isVisible: false,
      text1: '',
      text2: '',
    })
  }

  return (
    <Context.Provider value={{ setConfirmItem: setWarningItem }}>
      {warningItem.isVisible && (
        <div className="warning-modal">
          <div className="warning-modal-content">
            <div className="warning-modal-content-text">
              {warningItem.text1}
            </div>
            <div className="warning-modal-content-text">
              {warningItem.text2}
            </div>
            <div className="warning-modal-content-close" onClick={onClose}>
              <Circle />
            </div>
          </div>
        </div>
      )}
      {children}
    </Context.Provider>
  )
}

export { WarningProvider, useWarning }
