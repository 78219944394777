import React, {useState, useEffect, useContext} from 'react';
import {v4 as uuid} from 'uuid';
import './styles.css';
import Circle from "../../assets/icons/Circle";

const Context = React.createContext();

const useAlert = () => {
    const {
        setList
    } = useContext(Context);

    return ({status = '', title = '', message = ''}) => {
        const id = uuid();
        setList((prev) => [
            ...prev,
            {
                id,
                status,
                title,
                message
            }
        ])
    };
}

const AlertItem = ({item, onDeletListItem}) => {
	const onDelete = () =>{
		onDeletListItem(item.id);
	}
    useEffect(() => {
        const timeoutID = setTimeout(() => {
			onDelete()
        }, 3000);
        return () => {
            clearTimeout(timeoutID);
        };
    }, []);
    return (
        <div key={item.id} style={{paddingTop: 10}}>
            <div className={`alert-notification-item ${item.status}`}>
				<div className="alert-notification-item-data">
					<p className="alert-notification-item__title">{item.title}</p>
					<p className="alert-notification-item__text">{item.message}</p>
				</div>
                <div className="alert-notification-item__circle" onClick={onDelete}>
                    <Circle color={'#fff'} w={24} h={24}/>
                </div>
            </div>
        </div>
    );
}

const AlertProvider = ({children}) => {
    const [list, setList] = useState([]);

    const onDeletListItem = (id) => {
        setList((prev) => prev.filter((item) => item.id !== id));
    }

    return (
        <Context.Provider value={{setList}}>
            <div className="alert-notification">
                {list.map((item) => (
                    <AlertItem key={item.id} item={item} onDeletListItem={onDeletListItem}/>
                ))}
            </div>
            {children}
        </Context.Provider>
    );
}

export {AlertProvider, useAlert};
