import React from 'react';

const ShowMewIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4 21H20C20.55 21 21 20.55 21 20V4C21 3.45 20.55 3 20 3H4C3.45 3 3 3.45 3 4V20C3 20.55 3.45 21 4 21ZM6 5C6.55 5 7 5.45 7 6C7 6.55 6.55 7 6 7C5.45 7 5 6.55 5 6C5 5.45 5.45 5 6 5ZM19 5V7H8V5H19ZM6 9C6.55 9 7 9.45 7 10C7 10.55 6.55 11 6 11C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9ZM19 9V11H8V9H19ZM6 13C6.55 13 7 13.45 7 14C7 14.55 6.55 15 6 15C5.45 15 5 14.55 5 14C5 13.45 5.45 13 6 13ZM19 13V15H8V13H19ZM6 17C6.55 17 7 17.45 7 18C7 18.55 6.55 19 6 19C5.45 19 5 18.55 5 18C5 17.45 5.45 17 6 17ZM19 17V19H8V17H19Z"
                fill="#fff"/>
        </svg>
    );
};

export default ShowMewIcon;