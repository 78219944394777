import { createSlice } from '@reduxjs/toolkit'

export const applicationSortSlice = createSlice({
  name: 'application',
  initialState: {
    currentMaster: {},
    masters: [],
  },
  reducers: {
    setCurrentMaster: (state, action) => {
      state.currentMaster = action.payload
    },
    setMasters: (state, action) => {
      state.masters = action.payload
    },
  },
})

export const { setCurrentMaster, setMasters } = applicationSortSlice.actions

export default applicationSortSlice.reducer
