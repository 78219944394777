import { useSelector, useDispatch } from 'react-redux'
import { setTokens, logout, setUser } from '../redux/auth'
import { getTransport, configureTransport, controller } from './transport'
import TokenStorage from './TokenStorage'
import { useNavigate } from 'react-router-dom'

let isRefreshed = {
  value: false,
}

const generateHook = (callback) => () => {
  // controller.abort()
  const dispatch = useDispatch()
  const refreshToken = TokenStorage.getRefreshToken()
  const navigate = useNavigate()

  return callback(async (e) => {
    if (e?.response?.status !== 401) throw e
    try {
      if (!refreshToken) throw new Error('refreshToken is empty')
      const res = await get('refresh', {
        headers: { Authorization: `Bearer ${refreshToken}` },
      }).then((res) => {
        if (res?.status === 'success') isRefreshed.value = true
        return res
      })
      console.log(res)
      await configureTransport(res.data.tokens.accessToken)
      await dispatch(
        setTokens({
          accessToken: res.data.tokens.accessToken,
          refreshToken: res.data.tokens.refreshToken,
        })
      )
      await dispatch(setUser(res.data.tokens.user))
      // navigate('/')

      await TokenStorage.setAccessToken(res.data.tokens.accessToken)
      await TokenStorage.setRefreshToken(res.data.tokens.refreshToken)
      await TokenStorage.setUser(res.data.tokens.user)

      const buf = (
        await getTransport()({
          ...e.response.config,
          headers: { Authorization: 'Bearer ' + res.data.tokens.accessToken },
        })
      )?.data
      navigate('/')
      return buf
    } catch (err) {
      console.log(err)
      if (!isRefreshed.value) {
        dispatch(logout())
        TokenStorage.logOut()
        navigate('/login')
        isRefreshed.value = false
        throw e
      }
      // else navigate('/')
    }
  })
}

export const usePost = generateHook(
  (
      middleware //функция принимающая колбек, возвращает результат этого колбека с прокинутой рефреш
    ) =>
    (path, payload, config) =>
      getTransport()
        .post(`/api/${path}`, payload, config)
        .then((response) => response.data)
        .catch(middleware)
)

export const useGet = generateHook(
  (middleware) => (path) =>
    getTransport()
      .get(`/api/${path}`)
      .then((response) => response.data)
      .catch(middleware)
)

export const usePut = generateHook(
  (middleware) => (path, payload, config) =>
    getTransport()
      .put(`/api/${path}`, payload, config)
      .then((response) => response.data)
      .catch(middleware)
)

export const usePatch = generateHook(
  (middleware) => (path, payload, config) =>
    getTransport()
      .patch(`/api/${path}`, payload, config)
      .then((response) => response.data)
      .catch(middleware)
)

export const useDelete = generateHook(
  (middleware) => (path, config) =>
    getTransport()
      .delete(`/api/${path}`, config)
      .then((response) => response.data)
      .catch(middleware)
)

export const get = (path, config) =>
  getTransport()
    .get(`/api/${path}`, config)
    .then((response) => response.data)

export const post = async (path, payload, config) =>
  getTransport()
    .post(`/api/${path}`, payload, config)
    .then((response) => response.data)

export const put = (path, payload = {}) =>
  getTransport()
    .put(`/api/${path}`, payload)
    .then((response) => response.data)

export const patch = (path, payload = {}) =>
  getTransport()
    .patch(`/api/${path}`, payload)
    .then((response) => response.data)

export const httpDelete = (path, config) =>
  getTransport()
    .delete(`/api/${path}`, config)
    .then((response) => response.data)
