import styles from './master-card.module.scss'

const MasterCard = ({ role, IgnoreTimeRange, fio, lift, timeIso }) => {
  const backgroundClasses = [styles.text]

  if (role === 'freelance_master') {
    backgroundClasses.push(styles.yellow)
  }

  if (
    (role === 'freelance_master' && IgnoreTimeRange === 1) ||
    role === 'regional_manager'
  ) {
    backgroundClasses.push(styles.first)
  }

  // const roleMap = {
  //   regional_manager: 'Региональный мастер',
  //   master: 'Мастер',
  //   freelance_master: 'Не штатный',
  // }

  return (
    <div className={styles.root}>
      {/* <h3 style={{ color: 'red' }}>{`Роль: ${roleMap[role]} `}</h3>
      <h3 style={{ color: 'blue' }}>
        {IgnoreTimeRange === 1
          ? 'без учета временного диапазона'
          : 'с учетом врем диапазона'}
      </h3> */}
      <div className={backgroundClasses.join(' ')}>
        <h3>{fio ? fio : '---'}</h3>
        <p>Подъем {lift}</p>
        <div className={styles.cityWrapper}>
          {timeIso}
        </div>
      </div>
    </div>
  )
}

export default MasterCard
