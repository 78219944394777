import { createSlice } from '@reduxjs/toolkit'
import { configureTransport } from '../../api/transport'

export const dragContentSlice = createSlice({
  name: 'dragContent',
  initialState: {
    isDragMain: false,
    isLoadingGrid: true,
    isGridUpdate: true,
    tickets: [],
    grid: [],
    gridCount: 2,
  },
  reducers: {
    setIsDragMain: (state, action) => {
      state.isDragMain = action.payload
    },
    setTickets: (state, action) => {
      state.tickets = action.payload
    },
    setNewTicket: (state, action) => {
      state.tickets = [action.payload, ...state.tickets]
    },
    setGrid: (state, action) => {
      state.grid = action.payload
    },
    setIsLoadingGrid: (state, action) => {
      state.isLoadingGrid = action.payload
    },
    setGridCount: (state, action) => {
      state.gridCount = action.payload
    },
    setIsGridUpdate: (state, action) => {
      state.isGridUpdate = action.payload
    },
  },
})

export const {
  setIsDragMain,
  setTickets,
  setNewTicket,
  setGrid,
  setIsLoadingGrid,
  setGridCount,
  setIsGridUpdate,
} = dragContentSlice.actions

export default dragContentSlice.reducer
