import { useState } from 'react'
import { useDispatch } from 'react-redux'
import './styles.scss'
import { PasswordHideIcon, PasswordIcon } from '../../../assets/icons/Login'

const Input = ({
  bcgColor = '#fff',
  isWithBoxShadow = false,
  value,
  onChange,
  leftIcon,
  error,
  rightIcon,
  title,
  password,
  ...props
}) => {
  const [isFocus, setIsFocus] = useState(false)
  const dispatch = useDispatch()

  const [hidePassword, setHidePassword] = useState(password)

  return (
    <div className="input">
      {!!title && <div className="input-title">{title}</div>}
      <div
        style={{
          backgroundColor: bcgColor,
        }}
        className="input__wrap-login"
      >
        {leftIcon && <div className="input__left">{leftIcon}</div>}
        <input
          className="input__input"
          type={hidePassword ? 'password' : 'text'}
          value={value}
          onChange={onChange}
          autoComplete="off"
          onFocus={() => {
            setIsFocus(true)
          }}
          onBlur={() => {
            setIsFocus(false)
          }}
          {...props}
        />
        {password && (
          <div
            onClick={() => setHidePassword(!hidePassword)}
            style={{ cursor: 'pointer' }}
          >
            {hidePassword ? <PasswordIcon /> : <PasswordHideIcon />}
          </div>
        )}
        {rightIcon && <div className="input__left">{rightIcon}</div>}
      </div>
      {error && typeof error == 'string' && (
        <div className="input__error">
          <p className="input__error-text">{error}</p>
        </div>
      )}
    </div>
  )
}

export default Input
