export const modifiedPhoneNumber = (phoneNumber) =>{
    if (phoneNumber?.length < 4) {
        return phoneNumber;
    }

    var result = phoneNumber?.substring(0, 2); // Первые два символа

    for (var i = 2; i < phoneNumber?.length - 2; i++) {
        result += "*";
    }

    result += phoneNumber?.substring(phoneNumber?.length - 2); // Последние два символа

    return result;
}