import { createSlice } from '@reduxjs/toolkit';

export const messagesSortSlice = createSlice({
    name: 'messages',
    initialState: {
        messages: [],
        messageCount: 0,
        isLoading: false,
        isLoadingPage: false,
        currentPage: 1,
        totalCount: 0,
    },
    reducers: {
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setTotalCount: (state, action) => {
            state.totalCount = action.payload;
        },
        initMessages: (state, action) => {
            state.messages = action.payload;
        },
        setMessages: (state, action) => {
            state.messages = [...state.messages, ...action.payload];
        },
        addNewOneMessage: (state, action) => {
            state.messages = [action.payload, ...state.messages];
        },
        setMessageCount: (state, action) => {
            state.messageCount = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsLoadingPage: (state, action) => {
            state.isLoadingPage = action.payload;
        },
    },
});

export const {
    setMessages,
    initMessages,
    addNewOneMessage,
    setMessageCount,
    setIsLoading,
    setIsLoadingPage,
    setCurrentPage,
    setTotalCount
} = messagesSortSlice.actions;

export default messagesSortSlice.reducer;
