import React from 'react';

const Search = ({color = '#fff'}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.6665 15.8333V14.1667H9.99984V15.8333H1.6665ZM1.6665 11.6667V10H5.83317V11.6667H1.6665ZM1.6665 7.5V5.83333H5.83317V7.5H1.6665ZM17.1665 15.8333L13.9582 12.625C13.6248 12.8611 13.2601 13.0383 12.864 13.1567C12.4679 13.275 12.0687 13.3339 11.6665 13.3333C10.5137 13.3333 9.53095 12.9269 8.71817 12.1142C7.90539 11.3014 7.49928 10.3189 7.49984 9.16667C7.49984 8.01389 7.90623 7.03111 8.719 6.21833C9.53178 5.40556 10.5143 4.99945 11.6665 5C12.8193 5 13.8021 5.40639 14.6148 6.21917C15.4276 7.03194 15.8337 8.01444 15.8332 9.16667C15.8332 9.56944 15.774 9.96889 15.6557 10.365C15.5373 10.7611 15.3604 11.1256 15.1248 11.4583L18.3332 14.6667L17.1665 15.8333ZM11.6665 11.6667C12.3609 11.6667 12.9512 11.4236 13.4373 10.9375C13.9234 10.4514 14.1665 9.86111 14.1665 9.16667C14.1665 8.47222 13.9234 7.88194 13.4373 7.39583C12.9512 6.90972 12.3609 6.66667 11.6665 6.66667C10.9721 6.66667 10.3818 6.90972 9.89567 7.39583C9.40956 7.88194 9.1665 8.47222 9.1665 9.16667C9.1665 9.86111 9.40956 10.4514 9.89567 10.9375C10.3818 11.4236 10.9721 11.6667 11.6665 11.6667Z"
                fill={color}/>
        </svg>
    );
};

export default Search;