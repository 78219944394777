import React, {useEffect, useState} from 'react';
import './styles.scss'
import {useNavigate} from "react-router-dom";
import BackButton from "../../components/Common/BackButton";
import SearchInput from "../../components/Common/SearchInput";
import Search from "../../assets/icons/Search";
import InstructionItem from "../../components/Instructions/InstructionItem";
import MessageItem from "../../components/Messages/MessageItem";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentPage, setIsLoading, setIsLoadingPage, setMessageCount} from "../../redux/messages";
import {useSocket} from "../../controllers/SocketProvider";
import Button from "../../components/Common/Button";
import {Oval} from "react-loader-spinner";

const Messages = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const {
        messages,
        messageCount,
        isLoading,
        isLoadingPage,
        currentPage,
        totalCount
    } = useSelector(state => state.messages)

    const {
        getMessagesByPage
    } = useSocket()


    const onNextPage = async () =>{
        await dispatch(setIsLoadingPage(true))
        await getMessagesByPage(currentPage + 1)
        await dispatch(setCurrentPage(currentPage + 1))
    }

    useEffect(() => {
        dispatch(setIsLoading(true))
        dispatch(setCurrentPage( 1))
        getMessagesByPage(1)
    }, [])

    useEffect(() => {
        dispatch(setMessageCount(0))
    }, [messageCount])


    return (
        <div className="messages">

            <div className="instructions-back">
                <BackButton
                    title={'Назад'}
                    onPress={() => navigate('/app/main')}
                />
            </div>

            <div className="messages-messages-wrap">
                {isLoading
                    ?
                    <div style={{
                        margin: '200px auto 0'
                    }}>
                        <Oval
                            strokeWidth={5}
                            strokeWidthSecondary={5}
                            secondaryColor={'rgba(253,99,61, 0.6)'}
                            height="50"
                            width="50"
                            color="#fff"
                        />
                    </div>
                    :
                    messages.length <= 0
                        ?
                        <div className="messages-messages-wrap-empty">
                            <div className="messages-messages-wrap-empty-title">
                                Нет сообщений
                            </div>
                            <div className="messages-messages-wrap-empty-desc">
                                На данный момент входящих сообщений нет
                            </div>
                        </div>
                        :
                        <>
                            {messages.filter((elem) => elem.recipient !== 2).map(item =>
                                <MessageItem
                                    item={item}
                                />
                            )}
                            <div style={{
                                margin: '10px auto',
                                width: 200
                            }}>
                                <Button
                                    disabled={totalCount === messages.length}
                                    isLoading={isLoadingPage}
                                    onClick={onNextPage}
                                    text={'Показать еще'}
                                />
                            </div>
                        </>
                }
            </div>
        </div>
    );
};

export default Messages;