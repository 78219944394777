import React from 'react';

const Circle = ({color = '#FD633D', w = 40, h = 40}) => {
    return (
        <svg width={w} height={h} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.6666 31.6666L8.33325 29.3333L17.6666 20L8.33325 10.6666L10.6666 8.33331L19.9999 17.6666L29.3333 8.33331L31.6666 10.6666L22.3333 20L31.6666 29.3333L29.3333 31.6666L19.9999 22.3333L10.6666 31.6666Z"
                fill={color}/>
        </svg>
    );
};

export default Circle;