import React from 'react';

const Find = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.5 17.5L13.8808 13.8809M13.8808 13.8809C14.4999 13.2618 14.991 12.5269 15.326 11.718C15.661 10.9091 15.8335 10.0422 15.8335 9.16672C15.8335 8.29121 15.6611 7.42429 15.326 6.61543C14.991 5.80657 14.4999 5.07162 13.8808 4.45255C13.2617 3.83348 12.5268 3.3424 11.7179 3.00736C10.9091 2.67232 10.0422 2.49988 9.16666 2.49988C8.29115 2.49988 7.42422 2.67232 6.61537 3.00736C5.80651 3.3424 5.07156 3.83348 4.45249 4.45255C3.20221 5.70282 2.49982 7.39856 2.49982 9.16672C2.49982 10.9349 3.20221 12.6306 4.45249 13.8809C5.70276 15.1312 7.3985 15.8336 9.16666 15.8336C10.9348 15.8336 12.6305 15.1312 13.8808 13.8809Z"
                stroke="white" strokeWidth="2.08333" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default Find;