import React, { useEffect, useRef, useState } from 'react'
import {
  SwapperArrowToLeft,
  SwapperArrowToRight,
} from '../../../assets/icons/Arrows'
import CardHeader from '../CardHeader'
import './styles.scss'
import { useOrder } from '../../../controllers/ModalOrder'
import SearchCardHeader from '../SearchCardHeader'
import { useDispatch, useSelector } from 'react-redux'
import { Oval } from 'react-loader-spinner'
import {
  setCurrentCity,
  setIsLoadingSearchMode,
  setIsSearchMode,
  setSearchTickets,
} from '../../../redux/headerSort'
import { setCurrentMaster } from '../../../redux/application'
import { useGet } from '../../../api/request'
import { useAlert } from '../../../controllers/AlertNotification'

const MainHeader = ({ tickets, searchTickets }) => {
  const [scroll, setScroll] = useState(false)
  const ref = useRef()

  const { isSearchMode, isLoadingSearchMode } = useSelector(
    (state) => state.headerSort
  )

  const next = () => {
    ref.current.scrollBy({
      behavior: 'smooth',
      left: 400,
    })
  }

  const prev = () => {
    ref.current.scrollBy({
      behavior: 'smooth',
      left: -400,
    })
  }
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 100) {
        setScroll(true)
      } else {
        setScroll(false)
      }
    }
    // window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  const renderTickets = () => {
    return (
      <>
        <div className="cards-header-title">Входящие заявки</div>
        <div className="cards-header-wrapper">
          <button className="cards-header-swapper-buttons-item" onClick={prev}>
            <span className={'cards-header-swapper-buttons-item-bottom'}>
              <SwapperArrowToLeft />
            </span>
          </button>
          <div className="cards-header-wrapper-card" ref={ref}>
            {tickets.map((card) => (
              <CardHeader key={card.id} id={card.id} card={card} />
            ))}
          </div>
          <button onClick={next} className="cards-header-swapper-buttons-item">
            <span className={'cards-header-swapper-buttons-item-top'}>
              <SwapperArrowToRight />
            </span>
          </button>
        </div>
      </>
    )
  }

  const renderTicketsFromSearch = () => {
    return (
      <>
        {isLoadingSearchMode ? (
          <Oval
            strokeWidth={5}
            strokeWidthSecondary={5}
            secondaryColor={'rgba(253,99,61, 0.6)'}
            height="20"
            width="20"
            color="#fff"
          />
        ) : (
          <>
            <div className="cards-header-title">Найденная заявка</div>
            <div className="cards-header-wrapper">
              <div className="cards-header-wrapper-card" ref={ref}>
                <SearchCardHeader searchTickets={searchTickets} />
              </div>
            </div>
          </>
        )}
      </>
    )
  }

  return (
    <div 
    className={`cards-header ${scroll && 'top'}`} 
    style={{
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex:99
      }}
    >
      {isSearchMode ? renderTicketsFromSearch() : renderTickets()}
    </div>
  )
}

export default MainHeader
