import React, {useState, useEffect} from 'react';
import "./index.scss";
import {Outlet} from 'react-router-dom';
import Header from "../../components/HeaderComponents/Header";
import Footer from "../../components/Footer";

const LoginLayout = () => {

    return (
        <div className="container-login">
            <Outlet/>
        </div>
    );
};

export default LoginLayout;
