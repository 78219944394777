import React, { useEffect } from 'react'
import Burger from '../../../assets/icons/Burger'
import { ArrowToBottom, ArrowToTop } from '../../../assets/icons/Arrows'
import './styles.css'
import './styles.scss'

const MessageItem = ({ item }) => {
    const setClassNameForImgTag = () => {
        const allElements = document
            .querySelector('.message-item-desc')
            .querySelectorAll('img')

        console.log(allElements)
        allElements.forEach((elem) => {
            elem.setAttribute('class', 'image__from__ckeditor')
        })

        console.log(allElements)
    }

    useEffect(() => {
        setClassNameForImgTag()
    }, [])
    return (
        <div className="message-item">
            <div className="message-item-title">
                <Burger />
                <div className="message-item-title-text">{item.title}</div>
            </div>
            <div
                className="message-item-desc"
                dangerouslySetInnerHTML={{ __html: item.data }}
            />
        </div>
    )
}

// const ParseTitle = (title) => (

// )

export default MessageItem
