import React from 'react';

export const BodyIcon = () => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.75 8.75C10.96 8.75 12.75 6.96 12.75 4.75C12.75 2.54 10.96 0.75 8.75 0.75C6.54 0.75 4.75 2.54 4.75 4.75C4.75 6.96 6.54 8.75 8.75 8.75ZM8.75 10.75C6.08 10.75 0.75 12.09 0.75 14.75V16.75H16.75V14.75C16.75 12.09 11.42 10.75 8.75 10.75Z" fill="#37383D"/>
        </svg>
    );

};
export const PasswordIcon = () => {
    return (
        <svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5 4C14.26 4 16.5 6.24 16.5 9C16.5 9.65 16.37 10.26 16.14 10.83L19.06 13.75C20.57 12.49 21.76 10.86 22.49 9C20.76 4.61 16.49 1.5 11.49 1.5C10.09 1.5 8.75 1.75 7.51 2.2L9.67 4.36C10.24 4.13 10.85 4 11.5 4ZM1.5 1.27L3.78 3.55L4.24 4.01C2.58 5.3 1.28 7.02 0.5 9C2.23 13.39 6.5 16.5 11.5 16.5C13.05 16.5 14.53 16.2 15.88 15.66L16.3 16.08L19.23 19L20.5 17.73L2.77 0L1.5 1.27ZM7.03 6.8L8.58 8.35C8.53 8.56 8.5 8.78 8.5 9C8.5 10.66 9.84 12 11.5 12C11.72 12 11.94 11.97 12.15 11.92L13.7 13.47C13.03 13.8 12.29 14 11.5 14C8.74 14 6.5 11.76 6.5 9C6.5 8.21 6.7 7.47 7.03 6.8ZM11.34 6.02L14.49 9.17L14.51 9.01C14.51 7.35 13.17 6.01 11.51 6.01L11.34 6.02Z" fill="#37383D"/>
        </svg>
    );
};

export const PasswordHideIcon = () => {
    return (
        <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.75 4.90625C10.9544 4.90625 10.1913 5.22232 9.62868 5.78493C9.06607 6.34754 8.75 7.1106 8.75 7.90625C8.75 8.7019 9.06607 9.46496 9.62868 10.0276C10.1913 10.5902 10.9544 10.9062 11.75 10.9062C12.5456 10.9062 13.3087 10.5902 13.8713 10.0276C14.4339 9.46496 14.75 8.7019 14.75 7.90625C14.75 7.1106 14.4339 6.34754 13.8713 5.78493C13.3087 5.22232 12.5456 4.90625 11.75 4.90625ZM11.75 12.9062C10.4239 12.9062 9.15215 12.3795 8.21447 11.4418C7.27678 10.5041 6.75 9.23233 6.75 7.90625C6.75 6.58017 7.27678 5.3084 8.21447 4.37072C9.15215 3.43303 10.4239 2.90625 11.75 2.90625C13.0761 2.90625 14.3479 3.43303 15.2855 4.37072C16.2232 5.3084 16.75 6.58017 16.75 7.90625C16.75 9.23233 16.2232 10.5041 15.2855 11.4418C14.3479 12.3795 13.0761 12.9062 11.75 12.9062ZM11.75 0.40625C6.75 0.40625 2.48 3.51625 0.75 7.90625C2.48 12.2962 6.75 15.4062 11.75 15.4062C16.75 15.4062 21.02 12.2962 22.75 7.90625C21.02 3.51625 16.75 0.40625 11.75 0.40625Z" fill="#37383D"/>
        </svg>

    );
};
