import { createSlice } from '@reduxjs/toolkit'

export const instructionsSlice = createSlice({
  name: 'instructions',
  initialState: {
    section: [],
  },
  reducers: {
    setSection: (state, action) => {
      state.section = action.payload
    },
  },
})

export const { setSection } = instructionsSlice.actions

export default instructionsSlice.reducer
