import { useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import { Provider, useSelector } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useRoutes, useNavigate, useLocation } from 'react-router-dom'
import store, { persistor } from './store'
import navigation from './navigation'
import AuthController from './controllers/AuthController'
import TokenStorage from './api/TokenStorage'
import { ThemeProvider, StyledEngineProvider } from '@material-ui/core'
import theme from './theme'
import { Loader } from './components/Common/Loader'
import { ConfirmProvider } from './controllers/ModalConfirm'
import AdapriveController from './controllers/AdapriveController'
import { WarningProvider } from './controllers/ModalWarning'
import { OrderProvider } from './controllers/ModalOrder'
import { CommentProvider } from './controllers/ModalComment'
import { AlertProvider } from './controllers/AlertNotification'
import { ImgProvider } from './controllers/ModalImg'

const App = () => {
  const content = useRoutes(navigation)
  const navigate = useNavigate()

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <PersistGate loading={null} persistor={persistor}>
          <DndProvider backend={HTML5Backend}>
            <AuthController>
              <AlertProvider>
                <ConfirmProvider>
                  <ImgProvider>
                    <AdapriveController>
                      <WarningProvider>
                        <CommentProvider>
                          <AlertProvider>{content}</AlertProvider>
                        </CommentProvider>
                      </WarningProvider>
                    </AdapriveController>
                  </ImgProvider>
                </ConfirmProvider>
              </AlertProvider>
            </AuthController>
          </DndProvider>
        </PersistGate>
      </ThemeProvider>
    </Provider>
  )
}

export default App
