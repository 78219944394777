import React from 'react';

const LogOut = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_421_334)">
                <path
                    d="M20 5C20.4248 5.00047 20.8333 5.16313 21.1422 5.45475C21.4511 5.74636 21.637 6.14492 21.6619 6.56899C21.6868 6.99306 21.5488 7.41063 21.2762 7.73639C21.0035 8.06214 20.6168 8.27149 20.195 8.32167L20 8.33333H11.6666C11.2584 8.33339 10.8644 8.48326 10.5593 8.75453C10.2543 9.02579 10.0594 9.39958 10.0116 9.805L9.99996 10V30C10 30.4082 10.1499 30.8022 10.4212 31.1073C10.6924 31.4123 11.0662 31.6072 11.4716 31.655L11.6666 31.6667H19.1666C19.5914 31.6671 20 31.8298 20.3089 32.1214C20.6178 32.413 20.8037 32.8116 20.8286 33.2357C20.8535 33.6597 20.7155 34.0773 20.4429 34.4031C20.1702 34.7288 19.7835 34.9382 19.3616 34.9883L19.1666 35H11.6666C10.3913 35.0001 9.1641 34.5128 8.23619 33.6379C7.30828 32.7629 6.74978 31.5665 6.67496 30.2933L6.66663 30V10C6.66655 8.72465 7.15383 7.49748 8.02877 6.56957C8.9037 5.64166 10.1001 5.08315 11.3733 5.00833L11.6666 5H20ZM29.5116 14.1067L34.225 18.8217C34.5374 19.1342 34.7129 19.5581 34.7129 20C34.7129 20.4419 34.5374 20.8658 34.225 21.1783L29.5116 25.8933C29.1989 26.2058 28.7748 26.3813 28.3327 26.3812C27.8906 26.381 27.4666 26.2052 27.1541 25.8925C26.8416 25.5798 26.6661 25.1557 26.6663 24.7136C26.6664 24.2715 26.8422 23.8475 27.155 23.535L29.0233 21.6667H20C19.5579 21.6667 19.134 21.4911 18.8214 21.1785C18.5089 20.8659 18.3333 20.442 18.3333 20C18.3333 19.558 18.5089 19.134 18.8214 18.8215C19.134 18.5089 19.5579 18.3333 20 18.3333H29.0233L27.155 16.465C26.8422 16.1525 26.6664 15.7285 26.6663 15.2864C26.6661 14.8443 26.8416 14.4202 27.1541 14.1075C27.4666 13.7948 27.8906 13.619 28.3327 13.6188C28.7748 13.6187 29.1989 13.7942 29.5116 14.1067Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_421_334">
                    <rect width="40" height="40" fill="white"/>
                </clipPath>
            </defs>
        </svg>


    );
};

export default LogOut;