import {useState} from 'react';
import {useDispatch} from 'react-redux';
import './styles.scss';
import {PasswordHideIcon, PasswordIcon} from "../../../assets/icons/Login";

const OrderInput = ({
                        bcgColor = '#fff',
                        isWithBoxShadow = false,
                        value,
                        onChange,
                        leftIcon,
                        error,
                        rightIcon,
                        title,
                        password,
                        disabled,
                        ...props
                    }) => {

    const [isFocus, setIsFocus] = useState(false);
    const dispatch = useDispatch();

    const [hidePassword, setHidePassword] = useState(password)

    return (
        <div className="input-order">
            {!!title &&
            <div className="input-order-title">
                {title}
            </div>
            }
            <div
                style={{
                    backgroundColor: bcgColor,
                }}
                className="input-order__wrap-login"
            >
                <input
                    disabled={disabled}
                    className="input-order__input"
                    type={hidePassword ? 'password' : "text"}
                    value={value}
                    onChange={onChange}
                    autoComplete="off"
                    onFocus={() => {
                        setIsFocus(true);
                    }}
                    onBlur={() => {
                        setIsFocus(false);
                    }}
                    {...props} />
            </div>
            {/*{(error && typeof error == 'string') && (*/}
            {/*    <div className="input__error">*/}
            {/*        <p className="input__error-text">{error}</p>*/}
            {/*    </div>*/}
            {/*)}*/}
        </div>
    );
};

export default OrderInput;
