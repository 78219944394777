import React, { useEffect, useState } from 'react'
import './styles.scss'
import { useNavigate } from 'react-router-dom'
import BackButton from '../../components/Common/BackButton'
import SearchInput from '../../components/Common/SearchInput'
import Search from '../../assets/icons/Search'
import InstructionItem from '../../components/Instructions/InstructionItem'
import { useGet } from '../../api/request'
import { useAlert } from '../../controllers/AlertNotification'
import { Oval } from 'react-loader-spinner'
import { useDispatch } from 'react-redux'
import { setSection } from '../../redux/instructions/instructions'
import { useSelector } from 'react-redux'

const Instructions = () => {
    const navigate = useNavigate()
    const getU = useGet()
    const alertU = useAlert()
    const [search, setSearch] = useState('')
    const [sections, setSections] = useState([])
    const [instruction, setInstruction] = useState([])
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(true)

    const getInstructions = async () => {
        setIsLoading(true)
        try {
            const response = await getU(`section/redactor/1`)

            if (response.status === 'success') {
                dispatch(setSection(response.data.data))
                const instructionMain = response.data.data[0].instruction_ids
                const sections = response.data.data.map((item) => ({
                    id: item.id,
                    name: item.name || item,
                    parentId: item.parent_section,
                    instructions: item.instructions,
                    instructionIds: item.instruction_ids,
                    level: item.level
                }))

                const rootSections = sections.filter(
                    (section) => section.parentId === null
                )
                const buildHierarchy = (sections, parentId, level) => {
                    const childSections = sections
                        .filter((section) => section.parentId === parentId)
                        .map((section) => ({
                            ...section,
                            level: level,
                            childSections: buildHierarchy(sections, section.id, level + 1),
                            instructions: section.instructionIds
                                ? section.instructionIds.map((instructionId) => {
                                    const find = sections.find(
                                        (item) => item.id === instructionId
                                    )
                                    return {
                                        id: instructionId,
                                        title: find.name.title,
                                        type: 'instruction',
                                        data: find.name.data
                                    }
                                })
                                : []
                        }))

                    return childSections.length > 0 ? childSections : null
                }
                const hierarchicalSections = rootSections.map((rootSection) => ({
                    id: rootSection.id,
                    name: rootSection.name,
                    childSections: buildHierarchy(sections, rootSection.id),
                    instructions: instructionMain && instructionMain
                }))
                console.log('Hierarchical sections:', hierarchicalSections)
                setSections(hierarchicalSections)
            }
        } catch (error) {
            alertU({
                status: 'error',
                title: 'Получение инструкций',
                message: error?.response?.data?.message
            })
        } finally {
            setIsLoading(false)
        }
    }
    const getInstructionSearch = async () => {
        const { data } = await getU(`/instruction?recipient=1&title=${search}`)
        setInstruction(data.Instruction)
    }
    const onClear = () => {
        setSearch('')
        setInstruction([])
    }

    useEffect(() => {
        getInstructions()
    }, [])
    useEffect(() => {
        if (!search) setInstruction([])
    }, [search])
    return (
        <div className="instructions">
            <div className="instructions-back">
                <BackButton title={'Назад'} onPress={() => navigate('/app/main')} />
            </div>
            <SearchInput
                onSearch={() => getInstructionSearch()}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder={'Поиск'}
                leftIcon={<Search />}
                onClear={() => onClear()}
            />
            <div className="instructions-instructions-wrap">
                {isLoading ? (
                    <div className="instructions-instructions-wrap-loader">
                        <Oval
                            strokeWidth={5}
                            strokeWidthSecondary={5}
                            secondaryColor={'rgba(253,99,61, 0.6)'}
                            height="50"
                            width="50"
                            color="#fff"
                        />
                    </div>
                ) : instruction && search ? (
                    instruction.map((item) => (
                        <div
                            onClick={() => navigate(`/app/instruction/${item.id}`)}
                            className="instruction-item"
                            key={item}
                        >
                            <p>{item.title}</p>
                        </div>
                    ))
                ) : (
                    <div>
                        {sections.map((item) => (
                            <InstructionItem item={item} key={item.id} />
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Instructions
