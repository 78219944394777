import { useDrag } from 'react-dnd'
import { сheckHours } from '../../../help/сheckHours'
import { modifiedPhoneNumber } from '../../../help/modifiedPhoneNumber'
import { modifiedAddress } from '../../../help/modifiedAddress'
import { useOrder } from '../../../controllers/ModalOrder'

const CalendarTicket = ({ ticket, hour, user, searchTickets }) => {
  const orderU = useOrder()
  const isContainTicket = hour.isContainsTakenTicket //принял заказ в работу и работает



  const [{ isDragging }, drag] = useDrag({
    type: 'ticket',
    item: () => {
      // Логика выбора первого тикета из массива ticket, если он есть
      const dragItem = Array.isArray(ticket) ? ticket[0] : ticket?.ticket
      // console.log(dragItem, 'drag item') // Лог для проверки item при инициации перетаскивания
      return { id: dragItem?.id }
    },
    canDrag: () => {
      return !isContainTicket && ticket?.ticket.manager_id === user?.id
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const onOpenCard = (id) => {
    orderU({
      isVisible: true,
      id: id,
      isWithMaster: true,
      visibleButton: true,
    })
  }

  const isWorkOrIsAsseptFrelance = () => {
    let backgroundColor = 'white';
    if (ticket.ticket.isTiketTaken) {
      backgroundColor = '#D2D2D2';
      // console.log(backgroundColor)
    }
    if (ticket.isFreelanceAccept === 1) {
      backgroundColor = '#D2D2D2'
    }

    return backgroundColor;
  }

  return (
    <div
      onClick={() => onOpenCard(ticket?.ticket.id)}
      ref={drag}
      id={ticket?.id}
      className="dates-block-dates-wrap-item-date-scroll-time-wrap-block-item"
      style={{
        opacity: сheckHours(
          hour?.time,
          hour?.isWork,
          ticket?.ticket?.manager_id === user?.id
        )
          ? 1
          : 0.5,
        border:
          searchTickets && searchTickets.id === ticket?.id
            ? '1px solid #FD633D'
            : '0px solid #fff',
        width:
          searchTickets && searchTickets.id === ticket?.id
            ? 'calc(100% - 9px)'
            : '100%',
        backgroundColor: isWorkOrIsAsseptFrelance(),
      }}
    >
      <h1>
        {ticket?.ticket?.manager_id === user?.id
          ? ticket?.ticket?.phone
          : modifiedPhoneNumber(ticket?.ticket?.phone)}
      </h1>

      {ticket?.ticket?.description && <h2>{ticket?.ticket?.description}</h2>}

      {ticket?.ticket?.manager_id && (
        <h3>
          {ticket?.ticket?.manager_id === user?.id
            ? ticket?.ticket?.address
            : modifiedAddress(ticket?.ticket?.address)}
        </h3>
      )}
      {ticket?.ticket?.price && <h4>{ticket?.ticket.price}</h4>}
      {ticket?.ticket?.work_hours && <h5>{ticket?.ticket.work_hours}ч</h5>}
    </div>
  )
}

export { CalendarTicket }
