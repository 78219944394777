import React, { useContext, useEffect, useState } from 'react'
import './styles.scss'
import Button from '../../components/Common/Button'
import Circle from '../../assets/icons/Circle'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'

const Context = React.createContext()

const useImgOpen = () => {
  const { setConfirmItem } = useContext(Context)

  return ({ isVisible = false, img = '' }) => {
    setConfirmItem({
      isVisible,
      img,
    })
  }
}

const ImgProvider = ({ children }) => {
  const [confirmItem, setConfirmItem] = useState({
    isVisible: false,
    img: '',
  })

  const onClose = () => {
    console.log('close')
    setConfirmItem({
      isVisible: false,
      img: '',
    })
  }

  return (
    <Context.Provider value={{ setConfirmItem }}>
      {confirmItem.isVisible && (
        <div className="modal">
          {confirmItem?.img && (
            <div>
              <TransformWrapper disablePadding={true}>
                <TransformComponent>
                  <img
                    src={confirmItem?.img}
                    alt="info__img"
                    className="img__modal-wr"
                  />
                </TransformComponent>
              </TransformWrapper>
            </div>
          )}

          <div className="order-modal-content-close" onClick={onClose}>
            <Circle />
          </div>
        </div>
      )}
      {children}
    </Context.Provider>
  )
}

export { ImgProvider, useImgOpen }
