import React, { useState } from 'react'
import './styles.scss'
import Input from '../../components/Common/Input'
import Button from '../../components/Common/Button'
import { BodyIcon, PasswordIcon } from '../../assets/icons/Login'
import loginBck1 from '../../assets/img/loginBck1.png'
import loginBck2 from '../../assets/img/loginBck2.png'
import loginBck3 from '../../assets/img/loginBck3.png'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setTokens, setUser } from '../../redux/auth'
import { post, usePost } from '../../api/request'
import { useAlert } from '../../controllers/AlertNotification'
import TokenStorage from '../../api/TokenStorage'

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const alertU = useAlert()

  const [values, setValues] = useState({
    password: '',
    email: '',
  })

  const [errors, setErrors] = useState({
    password: '',
    email: '',
  })

  const [isLoading, setIsLoading] = useState(false)

  const handleChangeValues = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    })
    setErrors({
      ...errors,
      [event.target.name]: '',
    })
  }

  const validation = () => {
    let isValid = true
    let formErrors = { ...errors }

    if (values.email === '') {
      formErrors.email = 'Поле должно быть запосленно'
      isValid = false
    }

    if (values.password === '') {
      formErrors.password = 'Поле должно быть запосленно'
      isValid = false
    } else if (values.password.length < 8) {
      formErrors.password = 'Минимальная длина пароля 8 символов'
      isValid = false
    }

    setErrors(formErrors)
    return isValid
  }

  const onSave = () => {
    if (!validation()) return
    setIsLoading(true)

    const data = {
      username: values.email,
      password: values.password,
    }

    post('login/manager', data)
      .then((response) => {
        console.log(response)
        if (response.status === 'success') {
          if (response.data.user.role === 'admin') {
            alertU({
              status: 'error',
              title: 'Авторизация',
              message: 'Администратор не может войти в приложение',
            })
          } else {
            dispatch(
              setTokens({
                accessToken: response.data.tokens.accessToken,
                refreshToken: response.data.tokens.refreshToken,
              })
            )
            dispatch(setUser(response.data.user))

            TokenStorage.setAccessToken(response.data.tokens.accessToken)
            TokenStorage.setRefreshToken(response.data.tokens.refreshToken)
            TokenStorage.setUser(response.data.user)

            navigate('/app/main')
          }
        }
      })
      .catch((e) => {
        console.log(e)
        if (e?.response?.data?.message?.includes('found')) {
          // setErrors({
          //     ...errors,
          //     email: 'Пользователь не найден'
          // })
          alertU({
            status: 'error',
            title: 'Авторизация',
            message: 'Не верный логин или пароль',
          })
        } else if (
          e?.response?.data?.message === 'Password or username are wrong'
        ) {
          alertU({
            status: 'error',
            title: 'Авторизация',
            message: 'Не верный логин или пароль',
          })
        } else {
          alertU({
            status: 'error',
            title: 'Авторизация',
            message: e?.response?.data?.message,
          })
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <div className="login-wrapper">
      <div className="login-wrapper-form">
        <h1 className="login-wrapper-form-title">Вход</h1>
        <p className="login-wrapper-form-text">
          Введите свои данные для того, чтобы зайти в аккаунт
        </p>

        <div className="login-wrapper-form-inputs">
          <Input
            title={'Логин'}
            placeholder="Введите email или логин"
            name="email"
            value={values.email}
            onChange={handleChangeValues}
            rightIcon={<BodyIcon />}
            error={errors.email}
          />
          <Input
            password
            title={'Пароль'}
            placeholder="Введите пароль"
            name="password"
            value={values.password}
            onChange={handleChangeValues}
            error={errors.password}
          />
        </div>

        <div style={{ marginTop: 86 }} />
        <Button
          onClick={onSave}
          isLoading={isLoading}
          text={'ВОЙТИ'}
          height={46}
        />
      </div>
      <div className="login-wrapper-plumb" />

      <img src={loginBck1} alt="" className="login-wrapper-back1" />
      <img src={loginBck2} alt="" className="login-wrapper-back2" />
      <img src={loginBck3} alt="" className="login-wrapper-back3" />
    </div>
  )
}

export default Login
