import {createSlice} from '@reduxjs/toolkit';
import {configureTransport} from '../../api/transport';

export const adaptiveSlice = createSlice({
    name: 'adaptive',
    initialState: {
        isMob: false,
        isDesktop: false
    },
    reducers: {
        setIsMob: (state, action) => {
            state.isMob = action.payload;
        },
        setIsDesktop: (state, action) => {
            state.isDesktop = action.payload;
        },
    },
});

export const {setIsMob, setIsDesktop} = adaptiveSlice.actions;

export default adaptiveSlice.reducer;
