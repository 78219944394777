import React, { useContext, useEffect, useState } from 'react'
import './styles.scss'
import Button from '../../components/Common/Button'
import Circle from '../../assets/icons/Circle'
import OrderInput from '../../components/MainTest/OrderInput'
import DropDownHours from '../../components/MainTest/DropDownHours'
import { ArrowToBottom } from '../../assets/icons/Arrows'
import DropDown from '../../components/HeaderComponents/DropDown'
import TextArea from '../../components/MainTest/TextArea'
import { useComment } from '../ModalComment'
import { useConfirm } from '../ModalConfirm'
import { useGet, usePut } from '../../api/request'
import { Oval } from 'react-loader-spinner'
import { useAlert } from '../AlertNotification'
import { modifiedPhoneNumber } from '../../help/modifiedPhoneNumber'
import { useSelector } from 'react-redux'
import { CheckJustTime } from '../../help/CheckJustTime'
import { Box, Tab, Tabs } from '@material-ui/core'

const Context = React.createContext()

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const useOrder = () => {
  const { setConfirmItem } = useContext(Context)

  return ({
    isVisible = false,
    id = '',
    isWithMaster = false,
    visibleButton,
  }) => {
    setConfirmItem({
      isVisible,
      id,
      isWithMaster,
      visibleButton,
    })
  }
}

const OrderProvider = ({ children }) => {
  const alertU = useAlert()
  const commentU = useComment()
  const getU = useGet()
  const putU = usePut()

  const { user } = useSelector((state) => state.auth)

  const [isLoading, setIsLoading] = useState(true)

  const [orderItem, setOrderItem] = useState({
    isVisible: false,
    id: '',
    isWithMaster: false,
  })

  const [ticket, setTicket] = useState({})
  const [selectedFour, setSelectedFour] = useState('2')

  const [listOfHours, setListOfHours] = useState([
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
  ])

  const [value, setValue] = useState(0)

  const [history, setHistory] = useState([])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const onChangeHour = (item) => {
    setIsLoading(true)
    if (orderItem?.id) {
      putU(`ticket/${orderItem.id}`, { work_hours: item })
        .then((response) => {
          if (response.status === 'success') {
            setTicket(response?.data?.ticket)
            setSelectedFour(String(response?.data?.ticket?.work_hours))

            alertU({
              status: 'success',
              title: 'Изменение времени на выполнение',
              message: 'Вы успешно изменили время',
            })
          }
        })
        .catch((e) => {
          console.log(e)
          alertU({
            status: 'error',
            title: 'Изменение времени на выполнение',
            message: e?.response?.data?.message,
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const onClose = () => {
    setOrderItem({
      isVisible: false,
      id: '',
      isWithMaster: false,
    })
    setValue(0)
  }

  const onCloseOrder = () => {
    commentU({
      isVisible: true,
      ticketId: orderItem.id,
      onClose: () => onClose(),
      isWithMaster: orderItem.isWithMaster,
    })
  }

  const goToCrm = () => {
    // console.log(ticket)
    window.open(ticket?.amoURL)
  }



  useEffect(() => {
    setIsLoading(true)
    if (orderItem?.id) {
      getU(`ticket/${orderItem.id}`)
        .then((response) => {
          if (response.status === 'success') {
            // console.log(response.data.ticket)
            setTicket(response?.data?.ticket)
            setSelectedFour(String(response?.data?.ticket?.work_hours))
          }
        })
        .catch((e) => {
          console.log(orderItem.id)
          alertU({
            status: 'error',
            title: 'Получение заявки',
            message: e?.response?.data?.message,
          })
          onClose()
        })
        .finally(() => {
          setIsLoading(false)
          if (orderItem.id) {
            getU(`ticket_history/${orderItem.id}`)
              .then((response) => {
                if (response.status === 'success') {
                  // console.log(response.data.ticket)
                  setHistory(response?.data?.history)
                }
              })
              .catch((e) => {
                // console.log(orderItem.id)
                alertU({
                  status: 'error',
                  title: 'Получение заявки',
                  message: e?.response?.data?.message,
                })
                onClose()
              })
              .finally(() => {
                setIsLoading(false)
              })
          }
        })
    }
  }, [orderItem.id])

  return (
    <Context.Provider value={{ setConfirmItem: setOrderItem }}>
      {orderItem.isVisible && (
        <div className="order-modal">
          <div className="order-modal-content">
            {isLoading ? (
              <Oval
                strokeWidth={5}
                strokeWidthSecondary={5}
                secondaryColor={'rgba(253,99,61, 0.6)'}
                height="30"
                width="30"
                color="#fff"
              />
            ) : (
              <>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Данные" {...a11yProps(0)} />
                    <Tab label="История" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                {value === 0 ? (
                  <TicketInfo
                    ticket={ticket}
                    selectedFour={selectedFour}
                    listOfHours={listOfHours}
                    onChangeHour={onChangeHour}
                    value={value}
                    user={user}
                  />
                ) : (
                  <TicketHistory history={history} value={value} />
                )}
                {ticket?.manager_id === user?.id && (
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      width: !orderItem.visibleButton && '85%',
                      justifyContent: !orderItem.visibleButton && 'flex-end',
                    }}
                  >
                    {orderItem.visibleButton &&  (
                      <div className="order-modal-content-button">
                        <Button
                          onClick={onCloseOrder}
                          height={40}
                          disabled={ticket.isTakenToWork}
                          text={ticket.isTakenToWork ? 'Заказ в работе' :"Отменить заказ"}
                        />
                      </div>
                    )}
                    <div style={{ padding: '0px 0px 0px 80px' }} />
                    <div className="order-modal-content-button">
                      <Button
                        onClick={goToCrm}
                        height={40}
                        text="Перейти в amoCRM"
                      />
                    </div>
                  </div>
                )}
                <div className="order-modal-content-close" onClick={onClose}>
                  <Circle />
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {children}
    </Context.Provider>
  )
}

const TicketInfo = ({
  ticket,
  selectedFour,
  listOfHours,
  onChangeHour,
  value,
  user,
}) => {
  return (
    <div className="order-modal-content-inputs" hidden={value === 0}>
      {value === 0 && (
        <>
          <div className="order-modal-content-inputs-column">
            <OrderInput
              disabled={true}
              title={'ID заказа'}
              value={ticket?.prettier_id}
            />
            <OrderInput
              disabled={true}
              title={'Категория'}
              value={ticket?.category?.category_name}
            />
            <OrderInput
              disabled={true}
              title={'Тип проблемы'}
              value={ticket?.type}
            />
            <TextArea
              rows={4}
              disabled={true}
              title={'Описание заказа'}
              value={ticket?.description}
            />
            <OrderInput
              disabled={true}
              title={'Цена менеджера (руб.)'}
              value={ticket?.price}
            />
          </div>
          <div className="order-modal-content-inputs-column">
            <OrderInput
              disabled={true}
              title={'Цена дороги (руб.)'}
              value={ticket?.road_cost}
            />
            <OrderInput
              disabled={true}
              title={'Перечислением (да/нет)'}
              value={
                ticket?.transfer === 1
                  ? 'Да'
                  : ticket?.transfer === 0
                  ? 'Нет'
                  : '---'
              }
            />
            <OrderInput
              disabled={true}
              title={'Телефон клиента'}
              value={
                ticket?.manager_id === user?.id
                  ? ticket?.phone
                  : modifiedPhoneNumber(ticket?.phone)
              }
            />
            <OrderInput
              disabled={true}
              title={'Имя клиента'}
              value={ticket?.client_name}
            />
            <OrderInput
              disabled={true}
              title={'Адрес'}
              value={ticket?.address}
            />
            {ticket?.manager_id === user?.id && (
              <DropDownHours
                currentItem={selectedFour}
                setCurrentItem={(item) => onChangeHour(item)}
                itemsForChoose={listOfHours}
                backIcon={<ArrowToBottom color={'#202328'} />}
              />
            )}
          </div>
        </>
      )}
    </div>
  )
}

const TicketHistory = ({ history, value }) => {
  return (
    <div className="order-modal-content-column">
      {value === 1 && (
        <>
          {history?.length ? (
            history?.map((el) => (
              <div style={{ padding: '10px 0 0 0' }}>
                <div>{el.date_create.replace(/T|(.000Z)/g, ' ')}</div>
                <div>{el.message}</div>
              </div>
            ))
          ) : (
            <div>Нет изменений</div>
          )}
        </>
      )}
    </div>
  )
}

export { OrderProvider, useOrder }
