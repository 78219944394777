import React from 'react';
import {useDrag} from "react-dnd";
import './styles.scss'
import {useOrder} from "../../../controllers/ModalOrder";

const SearchCardHeader = ({searchTickets}) => {

    const orderU = useOrder()

    const onOpenCard = (id) => {
        // console.log(id)
        orderU({
            isVisible: true,
            id: id,
            isWithMaster: false,
        })
    }

    return (
        <div
            onClick={() => onOpenCard(searchTickets?.id)}
            className="header-card-item"
            style={{
                backgroundColor: '#fff',
                border: '1px solid #000'
            }}
        >
            <div
                className="header-card-item-big-text"
                style={{
                    marginBottom: 2,
                    color: '#000'
                }}
            >
                {searchTickets?.phone}
            </div>
            <div
                className="header-card-item-small-text"
                style={{
                    marginBottom: 2,
                    color: '#000'
                }}
            >
                {searchTickets?.description}
            </div>
            <div
                className="header-card-item-big-text"
                style={{
                    marginBottom: 2,
                    color: '#000'
                }}
            >
                {searchTickets?.address.length > 23 ? searchTickets?.address.slice(0,20) + '...' : searchTickets.address}
            </div>
            <div
                className="header-card-item-big-text"
                style={{
                    marginBottom: 2,
                    color: '#000'
                }}
            >
                {searchTickets?.price} р.
            </div>
        </div>
    );
};

export default SearchCardHeader;