import React from 'react'
import './style.scss'
import { ArrowToLeft } from '../../../assets/icons/Arrows'

const BackButton = ({ title, onPress }) => {
  return (
    <div className="back-button" onClick={onPress}>
      <div className="back-button-icon">
        <ArrowToLeft />
      </div>
      <div className="back-button-text">{title}</div>
      <div />
    </div>
  )
}

export default BackButton
