import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import './styles.scss'
import { PasswordHideIcon, PasswordIcon } from '../../../assets/icons/Login'
import Search from '../../../assets/icons/Search'
import Find from '../../../assets/icons/Find'
import Circle from '../../../assets/icons/Circle'
import SearchCircle from '../../../assets/icons/SearchCircle'
import {
  setCurrentCity,
  setCurrentWorks,
  setIsLoadingSearchMode,
  setIsSearchMode,
  setSearchTickets,
} from '../../../redux/headerSort'
import { useGet } from '../../../api/request'
import { useAlert } from '../../../controllers/AlertNotification'
import { setCurrentMaster } from '../../../redux/application'

const SearchInput = ({
  bcgColor = '#37383D',
  isWithBoxShadow = false,
  value,
  onChange,
  leftIcon,
  error,
  rightIcon,
  title,
  password,
  width,
  isDarkMode,
  onClear,
  onSearch,
  ...props
}) => {
  const [isFocus, setIsFocus] = useState(false)

  const [hidePassword, setHidePassword] = useState(password)

  return (
    <div
      className="input-search"
      style={{
        width: width,
      }}
    >
      <div
        className="input-search__wrap"
        style={{
          backgroundColor: bcgColor,
        }}
      >
        {/*{leftIcon && (*/}
        {/*    <div className="input-search__left">*/}
        {/*        {leftIcon}*/}
        {/*    </div>*/}
        {/*)}*/}
        <input
          className={
            isDarkMode ? 'input-search__input-dark' : 'input-search__input'
          }
          type={hidePassword ? 'password' : 'text'}
          value={value}
          onChange={onChange}
          autoComplete="off"
          onFocus={() => {
            setIsFocus(true)
          }}
          onBlur={() => {
            setIsFocus(false)
          }}
          {...props}
        />

        {!!value && (
          <div className="input-search__wrap-clear" onClick={onClear}>
            <SearchCircle />
          </div>
        )}

        <div className="input-search__wrap-search" onClick={onSearch}>
          <Find />
        </div>
      </div>
    </div>
  )
}

export default SearchInput
