import moment from "moment";
import {checkHourOpacity} from "./checkHourOpacity";

export const сheckHours = (dateString, isWork, condition) => {

    const today = moment().format('YYYY-MM-DD HH');
    const currentDay = moment(dateString).format('YYYY-MM-DD HH');

    if (!isWork) {
        return false
    }

    if (dateString) {
        if (today < currentDay && condition) {
            return true;
        } else if (today < currentDay && !condition) {
            return false;
        } else {
            return false;
        }
    }
}