

export const getCurrentCityFilterSelect = (cardToDrag, currentCity) => {
  if (cardToDrag && cardToDrag.region) {
    return cardToDrag.region ? cardToDrag.region : null;
  }

  if (currentCity) {
    return currentCity ? currentCity : null;
  }
  return null;
}